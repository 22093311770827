<template>
  <div class="panel">
    <button @click="handleToggle">{{title}}</button>
    <ku4-panel :open="panelOpen">
      <div class="content">
        <slot></slot>
      </div>
    </ku4-panel>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: [ 'title', 'open' ],
  setup(props) {
    const panelOpen = ref(props.open)
    const handleToggle = () => panelOpen.value = !panelOpen.value;

    return {
      panelOpen,
      handleToggle
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';

.panel {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $pink;

  button {
    padding: 2rem;
    background-color: $white;
    font-size: 1.2rem;
    color: $dark-grey;
  }

  .content {
    padding: 1rem;
  }
}
</style>
