import { defineStore } from 'pinia'

export const useSuggestionStore = defineStore('suggestion', {
  state: () => ({
    suggestions: {},
    marked: null,
  }),
  getters: {
    items() {
      return Object.values(this.suggestions);
    }
  },
  actions: {
    list(items = []) {
      const suggestions = items.reduce((a, v) => (a[v.id] = v, a), {});
      this.$patch({ suggestions });
    },
    mark(suggestion) {
      this.marked = suggestion;
    },
    unmark() {
      delete this.marked;
    },
    remove(suggestion) {
      delete this.suggestions[suggestion.id];
    },
    reset() { this.$reset() }
  },
})
