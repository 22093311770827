/**
 * Performs the item removal animation. The item will need to
 * have a static class `item` and a dynamic class `removing`.
 *
 * @param item {Node} - Item node
 * @param method {Function} - Method that removes the item data, i.e. from a store.
 */
export default (item, method) => {
  item.addEventListener('transitionend', (e) => {
    if(e.propertyName === 'height') {
      try {
        item.parentNode.removeChild(item);
        method();
      }
      finally { }
    }
  });
  item.classList.add('remove');
}
