<template>
  <div class="image aspect-image">
    <div>
      <img
        :onerror="`this.onerror=undefined;this.src='${noImage}'`"
        :src="src || noImage"
        :alt="alt"
      />
    </div>
  </div>
</template>

<script>
import noImage from '@/assets/image/noImageAvailable.png';

export default {
  props: ['src', 'alt'],
  setup() {
    return {
      noImage
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/aspect-ratio';

.aspect-image {
  position: relative;
  display: block;

> * {
    @include aspect-ratio(100%);
    display: block;
    border-radius: 50%;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
</style>
