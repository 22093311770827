<template>
  <div class="splash">
    <img class="logo" :src="logo" alt="logo" />
    <div class="loading">
      Loading...
    </div>
  </div>
</template>

<script>
import logo from '@/assets/image/header_drawer.png'

export default {
  setup() {
    return {
      logo
    }
  }
}
</script>

<style scoped>
.splash {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #cc4388;
  color: #fff;
}

.logo {
  max-width: 300px;
}

.loading {
  margin: 1rem 0 0;
}
</style>
