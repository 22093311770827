import request, { PUT } from '@/capabilities/request';
import { useAccountStore } from '@/stores/account';
import { api } from '@/config';
const domain = api();

class NotificationService {

  constructor(accountStore) {
    this._accountStore = accountStore;
  }

  async subscribe(values) {
    const { token } = this._accountStore;
    return await request(PUT, `${domain}/notification/register`, values, token);
  }

}

let instance;
export const useNotificationService = () =>
  instance || (instance = new NotificationService(useAccountStore()), instance)
