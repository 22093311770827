<template>
  <div ref="itemRef" class="item suggestion" :class="{ removing }">
    <div class="main-content">
      <Media :list="media" :height="height" />
      <div class="price-container">
        <span class="price" :class="`${isDiscounted ? ' sale' : ''}`">
          {{ Money.tryParse(salePrice(price, discount)) }}
        </span>
        <div v-if="isDiscounted" class="original-price">{{(Money.tryParse(price) || '').toString()}}</div>
        <div v-if="isDiscounted" class="discount-info">
          <span>{{discount.name}}</span>
          <span>{{discount.code}}</span>
          <span>{{discount.description}}</span>
        </div>
      </div>
      <div class="controls" v-if="showControls !== false">
        <a class="control buy" :href="url" target="_blank"></a>
        <button class="control accept" :class="{ loading: creating}" @click="handleAccept"></button>
        <button class="control reject" :class="{ loading: !creating && deleting}" @click="handleReject"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Money } from 'ku4es-kernel';
import salePrice from '@/capabilities/salePrice';
import { useSuggestionService } from '@/services/suggestion';
import { useWishService } from '@/services/wish';
import removeItem from '@/capabilities/transitions/removeItem';
import Media from '@/components/Media';

export default {
  components: { Media },
  props: [
    'id', 'url', 'media', 'name', 'price', 'discount', 'showControls', 'height'
  ],
  setup(props) {
    const suggestionService = useSuggestionService();
    const wishService = useWishService();

    const itemRef = ref(null);
    const creating = ref(false);
    const removing = ref(false);

    const isDiscounted = computed(() => props.discount?.price || props.discount?.operation);

    const handleAccept = async () => {
      creating.value = true;
      await Promise.all([
        wishService.create({ url: props.url, quantity: 1 }),
        suggestionService.remove({ id: props.id, url: props.url }),
      ]);
      creating.value = false;
    }

    const handleReject = async () => {
      removing.value = true;
      removeItem(itemRef.value, () => suggestionService.remove({ id: props.id }));
    }

    return {
      Money,
      salePrice,
      itemRef,
      creating,
      removing,
      isDiscounted,
      handleAccept,
      handleReject
    }
  }
}
</script>
