import request, { GET } from '@/capabilities/request';
import {success} from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useVendorStore } from '@/stores/vendor';
import { api } from '@/config';
const domain = api();

class VendorService {

  constructor(accountStore, vendorStore) {
    this._accountStore = accountStore;
    this._vendorStore = vendorStore;
  }

  async list() {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/vendor/list`, null, token)
    if(success(response)) {
      const items = await response.json();
      this._vendorStore.list(items);
      return items;
    }
  }

}

let instance;
export const useVendorService = () =>
  instance || (instance = new VendorService(useAccountStore(), useVendorStore()), instance)
