import request, { GET, PATCH } from '@/capabilities/request';
import { useAccountStore } from '@/stores/account';
import { useSettingsStore } from '@/stores/settings';
import { api } from '@/config';
const domain = api();

class SettingsService {

  constructor(accountStore, settingsStore) {
    this._accountStore = accountStore;
    this._settingsStore = settingsStore;
  }

  async read() {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/settings/`, null, token);
    const settings = await response.json();
    this._settingsStore.update(settings);
    return settings;
  }

  async update({ filters }) {
    const { token } = this._accountStore;
    const { response } = await request(PATCH, `${domain}/settings/`, { filters }, token);
    const settings = await response.json();
    this._settingsStore.update(settings);
    return settings;
  }

}

let instance;
export const useSettingsService = () =>
  instance || (instance = new SettingsService(useAccountStore(), useSettingsStore()), instance)
