<template>
  <div class="control">
    <label :for="`filter-${value}`">{{value}}</label>
    <input :id="`filter-${value}`" :name="value" type="checkbox" :checked="checked" />
  </div>
</template>

<script>
export default {
  props: [ 'value', 'checked' ]
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/switch';

.control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

label::first-letter {
  text-transform: capitalize;
}

input {
  @include switch(32px);
}
</style>
