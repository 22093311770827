import List from '@/pages/Wish/List';
import Add from '@/pages/Wish/Add';
import PublicList from '@/pages/PublicList';
import WishList from '@/pages/Follow/WishList';

export default [
  {
    name: 'public-wish-list',
    path: '/public/wish/list/:pid',
    component: PublicList,
    props: true,
    meta: { auth: false }
  },
  {
    name: 'follow-wish-list',
    path: '/wish/list/:pid',
    component: WishList,
    props: true,
    meta: { auth: true }
  },
  {
    name: 'wish-list',
    path: '/wish/list',
    component: List,
    meta: { auth: true }
  },
  {
    name: 'wish-add',
    path: '/wish/add',
    component: Add,
    meta: { auth: true }
  }
]
