import request, {GET, POST} from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useWardStore } from '@/stores/ward';
import { api } from '@/config';
const domain = api();

class WardService {

  constructor(accountStore, wardStore) {
    this._accountStore = accountStore;
    this._wardStore = wardStore;
  }

  async list() {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/ward/list`, null, token);
    if(success(response)) {
      const items = await response.json();
      this._wardStore.list(items);
      return items;
    }
  }

  /**
   * Generate a code to share a ward with another person so they
   * can also manage the target ward, e.g. a mother can share
   * a child with the child's father
   * @param pid
   * @returns {Promise<void>}
   */
  async generateProxyShareCode(pid) {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/ward/proxy/${pid}`, null , token);
    return success(response) ? await response.json() : null;
  }

  /**
   * Connect to a ward with a generated proxy share code
   * generated using a call to `generateProxyShareCode`.
   * @param code
   * @returns {Promise<void>}
   */
  async connectToWardWithProxyCode(code) {
    const { token } = this._accountStore;
    const { response } = request(GET, `${domain}/ward/proxy/connect`, { code }, token)
    return success(response) ? await response.json() : null;
  }

  async create(first, middle, last, dob) {
    const { token } = this._accountStore;
    const { response } = await request(POST, `${domain}/ward`, {
      first,middle, last, dob: (new Date(dob)).toJSON(),
    }, token);
    if(success(response)) {
      const ward = await response.json();
      this._wardStore.create(ward);
      return ward;
    }
  }

}

let instance;
export const useWardService = () =>
  instance || (instance = new WardService(useAccountStore(), useWardStore()), instance)
