<template>
  <div class="page">
    <SubHeader title="Suggest" />
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting">
      <Suggestion class="suggestion"
        :url="marked.url"
        :media="marked.media"
        :name="marked.name"
        :price="marked.price"
        :discount="marked.discount"
        :show-controls="false"
        :height="'260px'"
      />
      <div class="list padded">
        <Suggest
          v-for="follow in follows" :key="follow.pid"
          :pid="follow.pid"
          :image="profileImage(follow.pid)"
          :first="follow.first"
          :middle="follow.middle"
          :last="follow.last"
          :url="marked.url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted} from 'vue';
import { Assert } from 'ku4es-kernel';
import profileImage from '@/capabilities/profileImage';
import {useFollowStore} from '@/stores/follow';
import {useSuggestionStore} from '@/stores/suggestion';
import {useFollowService} from '@/services/follow';
import SubHeader from '@/components/SubHeader';
import Spinner from '@/components/Spinner';
import Suggestion from '@/components/Suggestion';
import Suggest from '@/components/Suggest';

export default {
  components: {
    SubHeader,
    Spinner,
    Suggestion,
    Suggest,
  },
  setup() {
    const suggestionStore = useSuggestionStore();
    const followStore = useFollowStore();
    const followService = useFollowService();
    const mounting = ref(false);

    const marked = computed(() => suggestionStore.marked);
    const follows = computed(() => followStore.items);

    onMounted(() => ( async () => {
      mounting.value = true;
      await followService.list();
      mounting.value = false;
    })());

    return {
      Assert,
      profileImage,
      mounting,
      marked,
      follows,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/sizes';
@import '@/styles/classes';

.suggestion {
  position: sticky;
  top: 95px;
}

.follow-container {
  margin: 0 1rem;
}

</style>
