import request, {DELETE, GET, POST} from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useFollowStore } from '@/stores/follow';
import { api } from '@/config';
const domain = api();

class FollowService {

  constructor(accountStore, followStore) {
    this._accountStore = accountStore;
    this._followStore = followStore;
  }

  async list(pid) {
    const { token } = this._accountStore;
    const endpoint = pid ? `${domain}/follow/list/${pid}` : `${domain}/follow/list`;
    const { response } = await request(GET, endpoint, null, token)
    if(success(response)) {
      const items = await response.json();
      this._followStore.list(items);
      return items;
    }
  }

  async create(follow) {
    const { token } = this._accountStore;
    const { response } = await request(POST, `${domain}/follow`, follow, token);
    const newFollow = await response.json();
    this._followStore.create(newFollow);
    return newFollow;
  }

  async remove(follow) {
    const { token } = this._accountStore;
    const { response } = await request(DELETE, `${domain}/follow/${follow.pid}`, null, token);
    if(success(response)) {
      this._followStore.remove(follow);
      return follow;
    }
  }

}

let instance;
export const useFollowService = () =>
  instance || (instance = new FollowService(useAccountStore(), useFollowStore()), instance)
