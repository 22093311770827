import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    filters: {},
  }),
  getters: {
    tags() {
      return Object.keys(this.filters);
    }
  },
  actions: {
    update(settings = {}) {
      this.$patch(state =>{
        state.filters = settings.filters || {}
      });
    },
    reset() { this.$reset() }
  },
})
