<template>
  <div class="page vendor-list">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div class="list padded">
      <div v-if="Assert.isEmpty(list)" class="no-items">
        No products
      </div>
      <div ref="itemListRef" class="item-list list">
        <Item v-for="(item, index) in list" :key="index"
          :index="item.index"
          :url="item.url"
          :media="item.media.slice(0, 1)"
          :name="item.name"
          :price="item.price"
          :variants="item.variants"
          :discount="item.discount"
          :vendor="item.vendor.name"
          :logo="item.vendor.logo"
          :logo-background-color="item.vendor.backgroundColor"
        />
        <div ref="tailSentinelRef" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Assert } from 'ku4es-kernel';
import { subscribe } from 'ku4es-notification/client';
import { useProductStore } from '@/stores/product';
import { useSettingsStore } from '@/stores/settings';
import { useProductService } from '@/services/product';
import { useWishService } from '@/services/wish';
import Spinner from '@/components/Spinner';
import Item from '@/components/Product';
import {useNotificationService} from '@/services/notification';
import {VAPID_PUBLIC_KEY} from '@/config';

const limit = 20;
let skip = 0;
let frameStart = 0;
let frameSkip = 3;
let frameLength = 8;

const html = document.querySelector('html');

const router = useRouter();
const productStore = useProductStore();
const settingsStore = useSettingsStore();
const productService = useProductService();
const wishService = useWishService();
const notificationService = useNotificationService();

const itemListRef = ref(null);
const tailSentinelRef = ref(null);

const mounting = ref(false);
const listing = ref(false);

const list = computed(() => productStore.items.slice(frameStart, frameStart + frameLength));
const tags = computed(() => settingsStore.tags);

const getClientHeight  = (itemCount) => Array.from(itemListRef.value.children).slice(0,itemCount).reduce((a, v) => {
  a += v.getBoundingClientRect().height;
  return a;
}, 0);

const tailSentinel = new IntersectionObserver(
    ([entity]) => {
      if(entity.isIntersecting) {
          // const currentClientHeight = getClientHeight(5);
          // const currentScrollTop = html.scrollTop - 50;
          // frameStart += frameSkip;
          listProducts().then(() => {
            window.requestAnimationFrame(() => {
              // // It looks like the padding fixes the scroll, but you need to recalculate now that you are not using scroll top.
              // // And there is also still a flash, so you have a 60fps issue somewhere still.
              // const scrollTop = html.scrollTop - 50;
              // itemListRef.value.style.paddingTop = `${scrollTop + scrollTop - currentScrollTop + getClientHeight(2) - currentClientHeight}px`;
              // // html.scrollTop = html.scrollTop + html.scrollTop - currentScrollTop + getClientHeight(2) - currentClientHeight;
            })
          });
      }
    },
    {
      rootMargin: '0px',
      threshold: 1
    }
);

const listProducts = async () => {
  listing.value = true;
  await productService.list(skip, limit, tags.value);
  skip += limit;
  listing.value = false;
}

onMounted(() => ( async () => {
  mounting.value = !list.value || Assert.isEmpty(list.value);
  try {
    await Promise.all([
      listProducts(skip, limit),
      wishService.list()
    ]);
  }
  finally {
    tailSentinel.observe(tailSentinelRef.value);
    mounting.value = false;
  }
  try {
    const {endpoint, keys: {auth, p256dh}} = await subscribe('/sw.js', VAPID_PUBLIC_KEY);
    await notificationService.subscribe({auth, endpoint, p256dh});
  } catch(e) { console.warn(e); }
})());

</script>
