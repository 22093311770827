import { Assert } from 'ku4es-kernel';

export const readProduct = data =>
  Object.keys(data).reduce((a, k) => {
      const [z, y, x, v, u] = k.split('_');
      if(z && !a[z]) {
        a[z] = Assert.exists(y) ? {} : data[k];
      }
      if(y && !a[z][y]) {
        a[z][y] = Assert.exists(x) ? {} : data[k];
      }
      if(x && !a[z][y][x]) {
        a[z][y][x] = Assert.exists(v) ? {} : data[k];
        if (a[z][y].key && Assert.exists(a[z][y].value)) {
          a[z][a[z][y].key] = a[z][y].value;
          delete a[z][y];
        }
      }
      if(v && !a[z][y][x][v]) {
        a[z][y][x][v] = Assert.exists(u) ? {} : data[k];
        if (a[z][y][x].key && Assert.exists(a[z][y][x].value)) {
          a[z][y][a[z][y][x].key] = a[z][y][x].value;
          delete a[z][y][x];
        }
      }
      if(u && !a[z][y][x][v][u]) {
        a[z][y][x][v][u] = data[k];
        if (a[z][y][x][v].key && Assert.exists(a[z][y][x][v].value)) {
          a[z][y][x][a[z][y][x][v].key] = a[z][y][x][v].value;
          delete a[z][y][x][v];
        }
      }
      return a;
    }, {});
