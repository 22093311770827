import { Assert } from 'ku4es-kernel';
import request, { GET } from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useProductStore } from '@/stores/product';
import { api } from '@/config';
const domain = api();

class ProductService {

  constructor(productStore) {
    this._productStore = productStore;
  }

  async list(skip = 0, limit = 1, tags = []) {
    const s = skip < 1 ? 0 : skip;
    if(this._productStore && this._productStore.contains(skip, limit)) {
      return this._productStore.list(skip, limit);
    }
    else {
      const {response} = await request(GET, `${domain}/product/list?skip=${s}&limit=${limit}${!Assert.isNullOrEmpty(tags) ? `&tags=${tags.join(',')}` : ''}`)
      if (success(response)) {
        const items = await response.json();
        this._productStore.update(items, s, limit);
        return items;
      }
    }
  }

}

let instance;
export const useProductService = () =>
  instance || (instance = new ProductService(useProductStore()), instance)
