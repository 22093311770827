import {Assert} from 'ku4es-kernel';
import { Dto } from 'ku4es-ui-kernel';

const defaultHeaders = { 'Content-Type': 'application/json' };
export const [ GET, POST, PUT, PATCH, DELETE ] = [ 'GET', 'POST', 'PUT', 'PATCH', 'DELETE' ];

export default async function request(
  method,
  url,
  parameters,
  token,
  headers,
) {
  const dto = new Dto(parameters || {});
  const params =
    parameters && ([GET, DELETE].includes(method)
      ? dto.toQueryString()
      : parameters instanceof File
        ? parameters
        : dto.toJson());

  const requestHeaders = parameters instanceof File ? {} : defaultHeaders;
  const options = {
    method,
    headers: token ? { ...requestHeaders, 'Authorization': `Bearer ${token}` } : headers,
    credentials: 'same-origin',
    body: method === GET ? undefined : params
  };

  const uri = method === GET && Assert.exists(parameters) ? `${url}?${parameters ? params : ''}` : url;

  /**
   * Return an AbortController to abort the current request
   * https://davidwalsh.name/cancel-fetch
   */
  return await fetch(uri, options)
    .then(response => ( { response, method, uri, options }))
    .catch(e => ({ e, method, uri, options }));
}
