<template>
  <div class="subheader">
    <button v-if="back !== false" class="back" @click="router.back">Back</button>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: [ 'back', 'title' ],
  setup() {
    const router = useRouter();

    return {
      router
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  margin: 12px 0 0 12px;
}

.title {
  display: block;
  margin: auto;
  font-size: 1.2rem;
  text-align: center;
}

</style>
