<template>
  <div class="search-result">
    <div class="info">
      <AspectImage :src="image" :alt="`${first},${last}`" />
      <div v-if="Assert.isNullOrEmpty(middle)">
        <span>{{first}}</span>&nbsp;
        <span>{{last}}</span>
      </div>
      <div v-if="!Assert.isNullOrEmpty(middle)">
        <span>{{first}}</span>&nbsp;
        <span>{{middle}}</span>&nbsp;
        <span>{{last}}</span>
      </div>
    </div>
    <button
      v-if="currentUser.pid !== pid"
      :class="{ loading: creatingFollow, following }"
      :disabled="following"
      @click="() => handleFollow(pid)"
    ></button>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Assert } from 'ku4es-kernel';
import { useAccountStore } from '@/stores/account';
import { useFollowStore } from '@/stores/follow';
import { useFollowService } from '@/services/follow';
import noImage from '@/assets/image/noImageAvailable.png';
import AspectImage from '@/components/AspectImage';

export default {
  components: { AspectImage },
  props: [ 'pid', 'image', 'first', 'middle', 'last'],
  setup(props) {
    const accountStore = useAccountStore();
    const followStore = useFollowStore();
    const followService = useFollowService();
    const inputRef = ref(null);
    const queryLength = ref(0);
    const creatingFollow = ref(false);
    const currentUser = computed(() => accountStore.account);
    const following = computed(() => Assert.exists(followStore.follows[props.pid]))
    const handleFollow = async (pid) => {
      creatingFollow.value = true;
      await followService.create({ pid });
      creatingFollow.value = false;
    }

    return {
      Assert,
      noImage,
      inputRef,
      queryLength,
      creatingFollow,
      currentUser,
      following,
      handleFollow
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/icon';
@import '@/styles/mixins/spinner';

button {
  @include icon('\2b', 1.1rem);
  display: flex;
  align-items: center;

  &[disabled] {
    opacity: 0.4;
  }

  &.loading {
    @include icon('');
    @include spinner(inherit, 6px);
  }

  &.following {
    @include icon('\f00c');
  }
}

.search-result {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem 0.4rem;

  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.image {
  width: 2rem;
  margin-right: 1rem;
  border: 2px solid $grey;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
}

</style>
