<template>
  <div class="ward">
    <AspectImage :src="image" :alt="`${first},${last}`" />
    <div class="info">
      <div class="name">
        <span v-if="first">{{first}}&nbsp;</span>
        <span>{{last}}</span>
      </div>
      <div class="controls" :class="{ disabled: switching || generatingProxyLoginCode || generatingWardProxy }">
        <button @click="handleSwitchUser" :class="{ loading: switching }">
          Switch user
        </button>
        <button @click="handleGenerateProxyLoginCode" :class="{ loading: generatingProxyLoginCode }">
          Login Code
        </button>
        <button @click="handleGenerateShareWardCode" :class="{ loading: generatingWardProxy }">
          Share child
        </button>
      </div>
    </div>

    <ku4-modal ref="proxyLoginModalRef"
      :visible="Assert.exists(proxyLoginCode)"
      :onDismiss="handleDismissProxyLoginModal"
    >
      <div class="proxy-code-container">
        <button @click="handleDismissProxyLoginModal">x</button>
        <h3 class="instructions">Enter code at login or scan QR on child's device to log them in.</h3>
        <h4 class="verification-code">{{proxyLoginCode && proxyLoginCode.code}}</h4>
        <QrCode :url="proxyLoginUrl" />
        <div class="disclaimer">
          Code is one-time-use and does expire.
          If code does not work, <a>regenerate</a> a new code.
        </div>
      </div>
    </ku4-modal>

    <ku4-modal ref="shareWardModalRef"
      :visible="Assert.exists(shareWardCode)"
      :onDismiss="handleDismissShareWardModal"
    >
      <div class="proxy-code-container">
        <button @click="handleDismissShareWardModal">x</button>
        <h3 class="instructions">Scan QR on other device to share child.</h3>
        <QrCode :url="shareWardUrl" />
        <div class="disclaimer">
          Code is one-time-use and does expire.
          If code does not work, <a>regenerate</a> a new code.
        </div>
      </div>
    </ku4-modal>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Assert } from 'ku4es-kernel';
import AspectImage from '@/components/AspectImage';
import { useAccountService } from '@/services/account';
import { useFollowService } from '@/services/follow';
import { useSettingsService } from '@/services/settings';
import { useSuggestionService } from '@/services/suggestion';
import { useWardService } from '@/services/ward';
import { useWishService } from '@/services/wish';
import QrCode from '@/components/QrCode';
import noImage from '@/assets/image/noImageAvailable.png';

export default {
  components: {
    AspectImage,
    QrCode
  },
  props: [
    'pid',
    'image',
    'first',
    'middle',
    'last',
    'switching'
  ],
  emits: ['switch'],
  setup(props, { emit }) {
    const accountService = useAccountService();
    const wardService = useWardService();

    const generatingProxyLoginCode = ref(false);
    const generatingWardProxy = ref(false);
    const proxyLoginCode = ref(null);
    const shareWardCode = ref(null);

    const proxyLoginUrl = computed(() =>
      proxyLoginCode.value ? `${location.origin}?pcode=${proxyLoginCode.value}` : null
    );

    const shareWardUrl = computed(() =>
      shareWardCode.value ? `${location.origin}?wcode=${shareWardCode.value})` : null
    );

    const handleSwitchUser = async () => {
      emit('switch', props.pid);
    }

    const handleGenerateProxyLoginCode = async () => {
      generatingProxyLoginCode.value = true;
      proxyLoginCode.value = await accountService.generateProxyLoginCode(props.pid);
      generatingProxyLoginCode.value = false;
    }

    const handleGenerateShareWardCode = async () => {
      generatingWardProxy.value = true;
      shareWardCode.value = wardService.generateProxyShareCode(props.pid);
      generatingWardProxy.value = false;
    }

    const handleDismissProxyLoginModal = () => proxyLoginCode.value = null;

    const handleDismissShareWardModal = () => shareWardCode.value = null;

    return {
      noImage,
      Assert,
      generatingProxyLoginCode,
      generatingWardProxy,
      proxyLoginCode,
      shareWardCode,
      proxyLoginUrl,
      shareWardUrl,
      handleSwitchUser,
      handleGenerateProxyLoginCode,
      handleGenerateShareWardCode,
      handleDismissProxyLoginModal,
      handleDismissShareWardModal,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/spinner';

button {
  margin: 0;
  padding: 0;
}

.ward {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 0;
}

.image {
  width: 60px;
  border: 2px solid $grey;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
}

.info {
  flex: 0 1 auto;
  margin: 0 0 0 1rem;
}

.name {
  font-size: 1.2rem;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 0.5rem 0 0;

  button {
    display: flex;
    align-items: center;
    width: 90px;
    color: $devil-black;

    &.loading {
      @include spinner(inherit, 6px);
      &::after {
        margin-left: 2px;
      }
    }
  }
}

.proxy-code-container {
  margin: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  background: $white;

  button {
    position: absolute;
    left: 50px;
    top: -10px;
    padding: 0.2rem 1rem 1rem;
    background: $white;
    font-size: 2rem;
    border-radius: 1rem;
  }

  .instructions {
    color: $black;
  }

  .verification-code {
    margin-top: 1rem;
    font-size: 4rem;
    line-height: 4.4rem;
    text-align: center;
    color: $devil-black;
  }
}

</style>
