<template>
  <div>
    <ku4-carousel ref="carouselRef" id="loginCarousel">
    <ku4-carousel-slide name="login-with-credentials">
      <div class="component">
        <div v-if="accountCreated">
          Account created. Login!
        </div>
        <ku4-form ref="ku4FormRef" @validate="handleValidate">
          <form ref="loginFormRef" :class="{ disabled: formDisabled }">
            <div class="field">
              <ku4-validation
                  for="username"
                  .pattern="required(regex.username)"
              >
                Email is required.
              </ku4-validation>
              <ku4-label for="username">
                <input
                    id="username"
                    name="username"
                    type="email"
                    placeholder="Email"
                    autocomplete="email"
                    autocorrect="off"
                    autocapitalize="none"
                />
              </ku4-label>
            </div>
            <span v-if="isPasswordReset" class="password-reset">Password changed. Login with new password.</span>
            <div class="field">
              <ku4-validation
                  for="password"
                  .pattern="required(regex.password)"
              >
                Password is required.
              </ku4-validation>
              <ku4-label for="password">
                <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    autocomplete="on"
                    autocapitalize="none"
                />
              </ku4-label>
            </div>
            <div class="form-controls">
              <div class="primary-controls">
                <button type="submit" class="primary" :class="{ loading: formSubmitting }">
                  {{ formSubmitting ? '' : 'Login' }}
                </button>
                <router-link :to="{ name: 'account-create' }" class="secondary create-account">
                  Create account
                </router-link>
              </div>
              <ku4-carousel-controls for="loginCarousel">
                <button class="tertiary" slide="login-with-code">Login child in with Code</button>
              </ku4-carousel-controls>
              <router-link :to="{ name: 'password-reset' }" class="tertiary">
                Forgot password
              </router-link>
            </div>
          </form>
        </ku4-form>
      </div>
    </ku4-carousel-slide>
    <ku4-carousel-slide name="login-with-code">
      <div class="component">
        <h2 class="verification-code-title">
          Enter the Login Code that you generated for your child.
        </h2>
        <ku4-form>
          <form ref="verificationFormRef" class="verification-code-form" :class="{ disabled: formDisabled }">
            <div class="field">
              <ku4-validation
                for="digit1, digit2, digit3, digit4, digit5, digit6"
                .pattern="required(regex.any)"
              >
                Login Code is required
              </ku4-validation>
              <div class="verification-code-fields">
                <input type="hidden" autocomplete="one-time-code" />
                <input id="digit1" name="digit-1" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
                <input id="digit2" name="digit-2" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
                <input id="digit3" name="digit-3" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
                <input id="digit4" name="digit-4" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
                <input id="digit5" name="digit-5" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
                <input id="digit6" name="digit-6" type="tel" maxlength="1" @touchstart="handleDigitTouch" @click="handleDigitClick" @keyup="handleDigitKeyUp" />
              </div>
            </div>
            <div class="form-controls">
              <div class="primary-controls">
                <button type="submit" class="primary" :class="{ loading: codeFormSubmitting }">
                  {{ codeFormSubmitting ? '' : 'Login' }}
                </button>
                <router-link :to="{ name: 'account-create' }" class="secondary create-account">
                  Create account
                </router-link>
              </div>
              <ku4-carousel-controls for="loginCarousel">
                <button class="tertiary" slide="login-with-credentials">Login with Email</button>
              </ku4-carousel-controls>
            </div>
            <p class="verification-code-footnote">
              Need a Login Code? You can generate one for your child in the
              <strong>Child Accounts</strong> section of <strong>your</strong> Menu after you
              login to <strong>your</strong> account!
            </p>

            <p class="verification-code-footnote">
              If your child will be using <strong>your</strong> device, you should login to
              <strong>your</strong>
              account, and then switch to their user from the <strong>Child Accounts</strong>
              section of <strong>your</strong> Menu.
            </p>

            <p class="verification-code-footnote">
              If you have not created an account for them yet,
              you can do that in the <strong>Child Accounts</strong> section of <strong>your</strong>
              Menu after you login.
            </p>
          </form>
        </ku4-form>
      </div>
    </ku4-carousel-slide>
  </ku4-carousel>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Assert } from 'ku4es-kernel';
import { wireForm } from 'ku4web-components/vue3';
import regex, { required } from '@/capabilities/regex';
import { useAccountStore } from '@/stores/account';
import { useTemporaryValuesStore } from '@/stores/temporaryValues';
import { useAccountService } from '@/services/account';

const route = useRoute();
const router = useRouter();
const accountStore = useAccountStore();
const temporaryValuesStore = useTemporaryValuesStore();
const accountService = useAccountService();

const carouselRef = ref(null);
const verificationFormRef = ref(null);
const ku4FormRef = ref(null);
const loginFormRef = ref(null);

const isPasswordReset = ref(false);
const formDisabled = ref(false);
const codeFormSubmitting = ref(false);
const formSubmitting = ref(false);

const accountCreated = computed(() => accountStore.isCreated)

const handleDigitTouch = (e) => { e.preventDefault(); e.target.select(); }
const handleDigitClick = (e) => { e.preventDefault(); e.target.select(); }
const handleDigitKeyUp = (e) => {
  e.preventDefault();
  try {
    if(e.key === "Tab" || e.key === "Shift") { return; }
    if (e.key === "Backspace") {
      const previous = e.target.previousElementSibling;
      previous.value = '';
      previous.focus();
    }
    else {
      if(e.key.length === 1) {
        e.target.value = e.key;
        e.target.nextElementSibling.select();
      }
    }
  }
  catch(e) {
    /** Fail silently **/
  }
}
const handleCodeSubmit = async (digits) => {
  const code = Object.values(digits).join('');
  if(!Assert.isNullOrEmpty(code)) {
    try {
      formDisabled.value = true;
      codeFormSubmitting.value = true;
      await accountService.loginWithProxyLoginCode(code.trim());
      router.push({name: 'product-list'});
    }
    catch(e) {
      console.error(e);
    }
    finally {
      codeFormSubmitting.value = false;
      formDisabled.value = false;
    }
  }
  return false;
}
const handleValidate = (e) => {
  if(e.target === ku4FormRef.value && e?.detail?.invalid?.length > 0) {
    e.target.focusFirstInvalid();
  }
}
const handleSubmit = async ({ username, password }) => {
  try {
    formDisabled.value = true;
    formSubmitting.value = true;

    await accountService.login(username.trim(), password.trim());

    if(temporaryValuesStore.isEmpty) {
      router.push({name: 'product-list'});
    }
    else {
      const { values, referrer } = temporaryValuesStore;
      temporaryValuesStore.reset();
      router.push({name: 'wish-add', query: { values, referrer }});
    }
  }
  catch(e) {
    console.error(e);
  }
  finally {
    formDisabled.value = false;
    formSubmitting.value = false;
  }
  return false;
}

onMounted(() => {
  const { slide, action } = route.query;
  wireForm(verificationFormRef, handleCodeSubmit)
  wireForm(loginFormRef, handleSubmit);
  if(slide) { carouselRef.value.slideTo(slide); }
  if(action === 'reset') { isPasswordReset.value = true }
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors.scss';

.component {
  margin-top: 4rem;
}

.verification-code-title {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $pink;
}

.verification-code-form {
  margin-top: 1rem;
}

.verification-code-fields {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 360px;

  input {
    height: 3.4rem;
    margin: 0 0.2rem;
    padding: 0;
    border-color: $dark-grey;
    border-radius: 4px;
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
  }
}

.verification-code-footnote {
  margin-top: 2rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-style: italic;
  color: $devil-black;
}

.form-controls {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  max-width: 360px;

  a, button {
    width: 100%;
    margin-top: 1rem;
  }

  .primary-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    a, button {
      flex: 0 1 48%;
      width: auto;
    }

    .create-account {
      font-size: 0.9rem;
    }
  }
}

</style>
