<template>
  <div class="container page component">
    <span class="instructions">
      Enter your new password and password confirm below.
    </span>
    <ku4-form>
      <form ref="formRef">
        <input v-show="false" type="email" autocapitalize="off" autocomplete="email" />
        <div class="field">
          <ku4-validation
            for="newPassword"
            pattern="^.{8,16}$"
          >
            New password must be 8-16 characters.
          </ku4-validation>
          <ku4-label for="newPassword" value="Password">
            <input
              id="newPassword"
              name="password"
              type="password"
              placeholder="New password"
              autocomplete="new-password"
            />
          </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="newConfirm"
            element="#newPassword"
          >
            Confirmation must match new password.
          </ku4-validation>
          <ku4-label for="newConfirm" value="Confirm Password">
            <input
              id="newConfirm"
              name="confirmation"
              type="password"
              placeholder="Confirm new password"
              autocomplete="new-password"
            />
          </ku4-label>
        </div>
        <div class="form-controls">
          <button
            type="submit"
            class="primary"
            :class="{ loading: resettingPassword }"
            :disabled="resettingPassword"
          >
            {{ resettingPassword ? '' : 'Reset password' }}
          </button>
            <router-link :to="{ name: 'intro' }" class="tertiary">
              Cancel
            </router-link>
          </div>
      </form>
    </ku4-form>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { wireForm } from 'ku4web-components/vue3';
import { useAccountService } from '@/services/account';

export default {
  props:['token'],
  setup(props) {
    const router = useRouter();
    const accountService = useAccountService();
    const formRef = ref(null);
    const resettingPassword = ref(false);
    const email = ref('');

    const handleSubmit = async ({ password, confirmation }) => {
      try {
        resettingPassword.value = true;
        await accountService.resetPassword(password, confirmation , props.token);
        router.push({ name: 'account-login', query: { slide: 'login-with-credentials', action: 'reset' } });
      }
      finally {
        resettingPassword.value = false;
      }
      return false;
    }

    onMounted(() => {
      wireForm(formRef, handleSubmit);
    });

    return {
      formRef,
      resettingPassword,
      email
    }
  }
}

</script>

<style lang="scss" scoped>

.component .instructions, {
  margin-top: 4rem;
}

.instructions,
.sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.success {
  display: none;
}

.sent form {
  display: none;
}

.sent .instructions {
  display: none;
}

.sent .success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto
}

.email {
  display: block;
  margin: 0.8rem 0;
  font-weight: 600;
}

.form-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem auto;
  max-width: 360px;

  a, button {
    flex: 0 1 48%;
    width: auto;
    margin-top: 1rem;
  }
}
</style>
