<template>
  <div class="page content">
    <ku4-form>
      <form ref="configFormRef">
        <ku4-grid columns-xs="1" columns-md="4">
          <ku4-col span-xs="1">
            <ku4-label for="configDomain">
              <input ref="domainRef"
                id="configDomain"
                name="domain"
                type="text"
                placeholder="Domain"
                @keyup="onDomainKeyUp"
              />
            </ku4-label>
            <button type="button" @click="list">Search</button>
            <ul class="path-list">
              <li v-for="path in pathList" :key="path">
                <button type="button" :class="{ loading: listing }" @click="() => selectPath(path)">{{path}}</button>
              </li>
            </ul>
          </ku4-col>
          <ku4-col span-xs="3">
            <ku4-label for="configPath">
              <input ref="pathRef"
                id="configPath"
                name="path"
                type="text"
                placeholder="Path"
                @keyup="onPathKeyUp"
              />
            </ku4-label>
            <textarea name="config" class="config" v-model="currentConfig" />
            <button type="submit" :class="{ loading: saving }">Save</button>
          </ku4-col>
        </ku4-grid>
      </form>
    </ku4-form>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import {Assert, debounce, opt} from 'ku4es-kernel';
import { wireForm } from 'ku4web-components/vue3';
import configTemplate from '@/capabilities/configTemplate';
import { useConfigStore } from '@/stores/config';
import { useConfigService } from '@/services/config';

export default {
  setup() {
    const store = useConfigStore();
    const service = useConfigService();

    const configFormRef = ref(null);
    const domainRef = ref(null);
    const pathRef = ref(null);

    const currentConfig = ref(null);
    const listing = ref(false);
    const saving = ref(false);

    const pathList = computed(() => store.paths);

    const list = async () => {
      listing.value = true;
      await service.list(domainRef.value.value);
      listing.value = false;
    }

    const selectPath = (path) => {
      const config = store.pathConfig(path);
      pathRef.value.value = path;
      currentConfig.value = Assert.isNullOrEmpty(domainRef.value?.value) ? '' : config
        ? JSON.stringify(opt(() => JSON.parse(store.pathConfig(path)), configTemplate) , null, 2)
        : JSON.stringify(configTemplate , null, 2)
    }

    const onDomainKeyUp = debounce(({ target }) => {
      pathRef.value.value = '';
      currentConfig.value = null;
    }, 500);

    const onPathKeyUp = debounce(({ target }) => {
      selectPath(target.value);
    }, 500);

    const onConfigSubmit = async ({ domain, path, config }) => {
      saving.value = true;
      try {
        await service.update({domain, path, config: JSON.parse(config)});
      }
      finally {
        saving.value = false;
      }
      return false;
    }

    onMounted(() => {
      wireForm(configFormRef, onConfigSubmit)
    });

    return {
      configFormRef,
      domainRef,
      pathRef,
      pathList,
      currentConfig,
      listing,
      saving,
      list,
      selectPath,
      onDomainKeyUp,
      onPathKeyUp
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/spinner';

button {
  &.loading {
    @include spinner(inherit, 6px);
    &::after {
      margin-left: 2px;
    }
  }
}

.content {
  margin-bottom: 240px;
}

.path-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.config {
  height: 500px;
}
</style>
