<template>
  <Splash v-if="!loaded" />
  <div v-if="loaded">
    <IntroHeader v-if="!isLoggedIn" />
    <AppHeader v-if="isLoggedIn && route.meta.header !== false" :image="accountImage">
      <template #control>
        <router-link
          class="header-menu"
          :to="{ name: 'menu' }"
        ></router-link>
      </template>
    </AppHeader>
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="route.path" />
    </router-view>
    <Navigation v-if="isLoggedIn && route.meta.nav !== false" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Assert, identity } from 'ku4es-kernel';
import profileImage from '@/capabilities/profileImage';
import { useAccountStore } from '@/stores/account';
import { useAccountService } from '@/services/account';
import Splash from '@/components/Splash';
import IntroHeader from '@/components/IntroHeader';
import AppHeader from '@/components/Header';
import Navigation from '@/components/Navigation';

const route = useRoute();
const accountStore = useAccountStore();
const accountService = useAccountService();
const loaded = ref(false);

const isLoggedIn = computed(() => Assert.exists(accountStore.token));
const accountImage = computed(() => profileImage(accountStore.account?.pid, identity.uid()))

onMounted( () => (async () => {
  await accountService.load();
  window.addEventListener("message", function ghOnloadListener(event) {
    const token = accountService.read().token;
    if(event.origin !== location.origin && event.data === 'gh-loaded') {
      event.source.postMessage(JSON.stringify({ name: 'gh-loaded', data: token }), event.origin);
      window.removeEventListener("message", ghOnloadListener);
    }
  });
  loaded.value = true;
})());
</script>

<style lang="scss">
@import '@/styles/lib';
.header-menu {
  @include icon('\f0c9', 20px);
  color: $grey;
}
</style>
