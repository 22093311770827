import request, {DELETE, GET, POST} from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useSuggestionStore } from '@/stores/suggestion';
import { api } from '@/config';
const domain = api();

class SuggestionService {

  constructor(accountStore, suggestionStore) {
    this._accountStore = accountStore;
    this._suggestionStore = suggestionStore;
  }

  async list() {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/suggestion/list`, null, token);
    if(success(response)) {
      const items = await response.json();
      this._suggestionStore.list(items);
      return items;
    }
  }

  mark(suggestion) {
    this._suggestionStore.mark(suggestion);
  }

  unmark() {
    this._suggestionStore.unmark();
  }

  async create(suggestion) {
    const { token } = this._accountStore;
    const { response } = await request(POST, `${domain}/suggestion`, suggestion, token);
    return await response.json();
  }

  async remove(suggestion) {
    const { token } = this._accountStore;
    const { response } = await request(DELETE, `${domain}/suggestion/${suggestion.id}`, null, token);
    if(success(response)) {
      this._suggestionStore.remove(suggestion);
      return suggestion;
    }
  }

}

let instance;
export const useSuggestionService = () =>
  instance || (instance = new SuggestionService(useAccountStore(), useSuggestionStore()), instance)
