<template>
  <div ref="containerRef" class="container">
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div ref="controlsRef" class="controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Swipe } from 'ku4es-ui-kernel';

export default {
  props: ['width'],
  setup(props) {
    const containerRef = ref(null);
    const controlsRef = ref(null);

    const handleSwipeLeft= () => {
      containerRef.value.classList.add('open');
      controlsRef.value.style.width = `${props.width}px`;
    }
    const handleSwipeRight= () => {
      containerRef.value.classList.remove('open');
      controlsRef.value.style.width = '0px';
    }

    onMounted(() => {
      const swipe = new Swipe(containerRef.value, 44);
      swipe.onLeft(handleSwipeLeft);
      swipe.onRight(handleSwipeRight);
    });

    return {
      containerRef,
      controlsRef,
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .content {
    flex: 1 0;
  }

  .controls {
    flex: 0 1 auto;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: width 0.4s ease;
  }
}
</style>
