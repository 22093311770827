<template>
  <div class="header" :class="{ [type]: type }">
    <ku4-grid columns-xs="7">
      <ku4-col span-xs="1">
        <slot name="control" />
      </ku4-col>
      <ku4-col span-xs="1">
        <div v-if="isGhAdmin" class="version">v{{version}}</div>
      </ku4-col>
      <ku4-col span-xs="3">
        <img class="logo small" :src="logo" alt="Gifthorse" />
        <span class="title">{{title}}</span>
      </ku4-col>
      <ku4-col span-xs="1">
      </ku4-col>
      <ku4-col span-xs="1">
        <AspectImage class="header-image" :src="image" alt="Header image" />
      </ku4-col>
    </ku4-grid>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useAccountStore } from '@/stores/account';
import noImage from '@/assets/image/noImageAvailable.png';
import logo from '@/assets/image/header_main.png';
import AspectImage from '@/components/AspectImage';

export default {
  components: { AspectImage },
  props: ['type', 'title', 'image'],
  setup() {
    const version = document.querySelector('meta[name="version"]').content;
    const accountStore = useAccountStore();
    const isGhAdmin = computed(() => (accountStore.account?.roles || []).includes('ghAdmin'));
    return {
      version,
      isGhAdmin,
      noImage,
      logo,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables/colors";
@import "@/styles/variables/sizes";

ku4-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header {
  --ku4-grid-column-gutter: 4px;
  --ku4-grid-row-gutter-xs: 0;
  --ku4-grid-row-gutter-sm: 0;
  --ku4-grid-row-gutter-md: 0;
  --ku4-grid-row-gutter-lg: 0;
  position: sticky;
  display: flex;
  flex: 0 1 $headerHeight;
  top: 0;
  height: $headerHeight;
  padding: 4px 0;
  background-color: $white;
  border-bottom: 4px solid $pink;
  color: $dark-grey;
  z-index: 3;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .version {
    font-size: 0.8rem;
  }

  .title {
    display: none;
  }

  &.menu,
  &.suggestion,
  &.wishlist {
    .logo,
    .app-spinner {
      display: none;
    }

    .title {
      display: inline;
    }
  }

  &.menu,
  &.suggestion,
  &.wishlist {
    border-color: transparent;
  }

  &.menu,
  &.suggestion {
    .image {
      display: none;
    }
  }

  &.suggestion,
  &.wishlist {
    background-color: $pink;
    color: $white;
  }
}

</style>
