import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Dto } from 'ku4es-ui-kernel';
import router from '@/router';
import App from '@/App.vue';
import '@/router/guards';
import { applyPolyfills, defineCustomElements } from 'ku4web-components/loader';
applyPolyfills().then(() => defineCustomElements());

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.mount('#app');

window.readAccount = async () => (await Dto.load('account'));
window.logout = async () => await (await Dto.load('account')).delete();
