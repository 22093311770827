import { defineStore } from 'pinia';
import { Assert } from 'ku4es-kernel';

export const useTemporaryValuesStore = defineStore('temporaryValues', {
  state: () => ({
    values: '',
    referrer: '',
  }),
  getters: {
    isEmpty() {
      return Assert.isNullOrEmpty(this.values) && Assert.isNullOrEmpty(this.referrer);
    }
  },
  actions: {
    setValues(values) {
      this.$patch(state => {
        state.values = values
      });
    },
    setReferrer(referrer) {
      this.$patch(state => {
        state.referrer = referrer
      });
    },
    reset() { this.$reset() }
  }
})
