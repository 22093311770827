export default {
  "name": {
    "selector": "",
    "property": "",
    "format": ""
  },
  "price": {
    "selector": "",
    "property": "",
    "format": ""
  },
  "media": {
    "selector": "",
    "attribute": ""
  },
  "variants": {
    "": {
      "selector": "",
      "value": {
        "selector": "",
        "property": "",
        "format": ""
      },
      "display": {
        "selector": "",
        "property": "",
        "format": ""
      }
    }
  },
  "variant": {
    "": {
      "selector": "",
      "value": {
        "selector": "",
        "property": "",
        "format": ""
      },
      "display": {
        "selector": "",
        "property": "",
        "format": ""
      }
    }
  }
}
