import List from '@/pages/Follow/List';

export default [
  {
    name: 'follow-list',
    path: '/follow/list',
    component: List,
    meta: { auth: true }
  },
]
