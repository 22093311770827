<template>
  <div class="page">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" class="list padded">
      <div v-if="Assert.isEmpty(list) && Assert.isEmpty(suggestionList)" class="no-items short">
        <router-link class="wishes" :to="{ name: 'product-list' }">
          Add wish
        </router-link>
      </div>
      <Suggestion
        v-for="item in suggestionList" :key="item.id"
        :id="item.id"
        :url="item.url"
        :media="item.media"
        :name="item.name"
        :price="item.price"
        :discount="item.discount"
      />
      <Wish
        v-for="item in list" :key="item.id"
        :id="item.id"
        :url="item.url"
        :media="item.media"
        :name="item.name"
        :price="item.price"
        :discount="item.discount"
        :variants="item.variants"
        :variant="item.variant"
        :quantity="item.quantity"
        :notes="item.notes"
        :vendor="item?.vendor?.name"
        :logo="item?.vendor?.logo"
        :logo-background-color="item?.vendor?.backgroundColor"
      />
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted} from 'vue';
import {Assert, opt} from 'ku4es-kernel';
import { useSuggestionStore } from '@/stores/suggestion';
import { useWishStore } from '@/stores/wish';
import { useSuggestionService } from '@/services/suggestion';
import { useWishService } from '@/services/wish';
import Spinner from '@/components/Spinner';
import Suggestion from '@/components/Suggestion'
import Wish from '@/components/Wish';

export default {
  components: {
    Spinner,
    Suggestion,
    Wish,
  },
  setup() {
    const suggestionStore = useSuggestionStore();
    const wishStore = useWishStore();
    const suggestionService = useSuggestionService();
    const wishService = useWishService();

    const mounting = ref(false);
    const suggestionList = computed(() => suggestionStore.items);
    const list = computed(() => wishStore.items);

    onMounted(() => ( async () => {
      mounting.value = !list.value || Assert.isEmpty(list.value);
      await Promise.all([
        suggestionService.list(),
        wishService.list()
      ]);
      mounting.value = false;
    })());

    return {
      Assert,
      opt,
      mounting,
      suggestionList,
      list,
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/classes';
</style>
