<template>
  <div class="component list padded">
    <ku4-form ref="ku4FormRef" @validate="handleValidate">
      <form ref="formRef">
        <div class="field">
          <ku4-validation
            for="first"
            .pattern="required(regex.name)"
          >
            First name can only contain letters.
          </ku4-validation>
          <ku4-label for="first">
          <input
            id="first"
            name="first"
            type="text"
            autocomplete="on"
            autocapitalize="on"
            placeholder="First Name"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="middle"
            .pattern="optional(regex.name)"
          >
            Middle can only container letters.
          </ku4-validation>
          <ku4-label for="middle">
          <input
            id="middle"
            name="middle"
            type="text"
            autocomplete="on"
            autocapitalize="on"
            placeholder="Middle Name (optional)"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="last"
            .pattern="required(regex.name)"
          >
            Last can only contain letters.
          </ku4-validation>
          <ku4-label for="last">
          <input
            id="last"
            name="last"
            type="text"
            autocomplete="on"
            autocapitalize="on"
            placeholder="Last Name"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
              for="dob"
              .pattern="required(regex.any)"
          >
            Valid birthday is required.
          </ku4-validation>
          <ku4-label for="dob">
          <ku4-mask for="dob" template="mm/dd/yyyy" char="[mdy]"></ku4-mask>
          <input
            id="dob"
            name="dob"
            type="text"
            autocomplete="on"
            inputmode="numeric"
            placeholder="Birthday"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="email"
            .pattern="required(regex.email)"
          >
            Valid email required.
          </ku4-validation>
          <ku4-label for="email">
          <input
            id="email"
            name="email"
            type="text"
            autocomplete="on"
            autocapitalize="off"
            placeholder="Email"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="createPassword"
            .pattern="required(regex.password)"
          >
            Password must be 8-16 characters.
          </ku4-validation>
          <ku4-label for="createPassword">
          <input
            id="createPassword"
            name="password"
            type="password"
            placeholder="Password"
            autocomplete="new-password"
          />
        </ku4-label>
        </div>
        <div class="field">
          <ku4-validation
            for="confirmPassword"
            element="#createPassword"
          >
            Password and confirmation password must match.
          </ku4-validation>
          <ku4-label for="confirmPassword">
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirm password"
            autocomplete="new-password"
          />
        </ku4-label>
        </div>
        <div class="form-controls">
          <button type="submit" class="primary" :disabled="creatingAccount">
            Sign up
          </button>
          <button type="button" class="tertiary" @click="router.back">
            Cancel
          </button>
        </div>
      </form>
    </ku4-form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { wireForm } from 'ku4web-components/vue3';
import regex, { required, optional } from '@/capabilities/regex';
import { useAccountService } from '@/services/account';

export default {
  setup() {
    const router = useRouter();
    const accountService = useAccountService();

    const ku4FormRef = ref(null);
    const formRef = ref(null);
    const creatingAccount = ref(false);

    const handleValidate = (e) => {
      if(e.target === ku4FormRef.value && e?.detail?.invalid?.length > 0) {
        e.target.focusFirstInvalid();
      }
    }
    const handleFormSubmit = async (data) => {
      creatingAccount.value = true;
      const account = Object.keys(data).reduce((a, k) => (a[k] = data[k].trim(), a), {});
      account.password = data.password;
      await accountService.create(account);
      await router.push({ name: 'account-login' });
      creatingAccount.value = false;
      return false;
    }

    onMounted(() => {
      wireForm(formRef, handleFormSubmit);
    });

    return {
      router,
      regex,
      required,
      optional,
      ku4FormRef,
      formRef,
      creatingAccount,
      handleValidate,
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  margin-top: 4rem;
}

.form-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem auto 0;
  max-width: 360px;

  a, button {
    flex: 0 1 48%;
    width: auto;
  }
}
</style>
