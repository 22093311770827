import request, { GET, POST, PUT } from '@/capabilities/request';
import { useAccountStore } from '@/stores/account';
import { useConfigStore } from '@/stores/config';
import { success } from '@/capabilities/response';
import { api } from '@/config';
const domain = api();

class ConfigService {

  constructor(accountStore, configStore) {
    this._accountStore = accountStore;
    this._configStore = configStore;
  }

  async list(vendor) {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/config/list`, { domain: vendor }, token);
    if(success(response)) {
      const config = await response.json();
      this._configStore.setConfig(config);
      return config;
    }
  }

  async update(config) {
    const { token } = this._accountStore;
    const { response } = await request(PUT, `${domain}/config`, config, token);
    if(success(response)) {
      const config = await response.json();
      this._configStore.updateConfig(config);
      return config;
    }
    else {
      const { response } = await request(POST, `${domain}/config`, config, token);
      if(success(response)) {
        const config = await response.json();
        this._configStore.updateConfig(config);
        return config;
      }
    }
  }

}

let instance;
export const useConfigService = () =>
  instance || (
    instance = new ConfigService(
      useAccountStore(),
      useConfigStore(),
    ), instance);
