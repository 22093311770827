import { defineStore } from 'pinia';

export const useAccountStore = defineStore('account', {
  state: () => ({
    pid: null,
    token: null,
    account: null,
    tokens: {},
    accounts: {},
    created: false // Temporarily true when you created an account to display the new account created message
  }),
  getters: {
    isCreated() {
      return this.created;
    },
    read() {
      return (value) => !value ? this.$state : this.$state[value];
    }
  },
  actions: {
    login(token, account) {
      this.$patch(state => {
        state.pid = account?.pid;
        state.token = token;
        state.account = account;
        state.created = false;
      });
    },
    loginAsWard(token, account) {
      const { pid } = this.account;
      this.$patch(state => {
        state.tokens[pid] = state.token;
        state.accounts[pid] = { ...state.account }
        state.token = token;
        state.account = account;
        state.created = false;
      });
    },
    create() {
      this.$patch({ created: true });
    },
    load(state) {
      this.$patch(state);
    },
    switchUser(pid) {
      this.loginAsWard(this.tokens[pid], this.accounts[pid])
    },
    update(updates) {
      this.$patch({ account: { ...this.account, ...updates } })
    },
    reset() { this.$reset() }
  },
})
