<template>
  <div class="qrcode" ref="qrCodeRef" />
</template>

<script>
import { ref, watch, onMounted, nextTick } from 'vue';
import QRCodeStyling from 'qr-code-styling';
import logo from '@/assets/image/logo.png'

export default {
  props: [ 'url' ],
  setup(props) {
    const qrCodeRef = ref(null);
    const qrCodeProps = (url) => ({
      width: 300,
      height: 300,
      margin: 0,
      type: 'svg',
      data: url,
      image: logo,
      qrOptions: {
        typeNumber:0,
        mode:"Byte",
        errorCorrectionLevel:"Q",
        scalable: true
      },
      backgroundOptions: {
        color: "#00000000",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        hideBackgroundDots:true,
        imageSize:0.4,
      },
      dotsOptions: {
        color: "#cc4388",
        type: "dots"
      },
      cornersSquareOptions: {
        color: '#cc4388',
        type: 'extra-rounded',
      },
      cornersDotOptions: {
        color: '#cc4388',
        type: 'dot',
      }
    });

    let qrCode;
    watch(() => props.url, (value) => {
      qrCode.update(qrCodeProps(value));
    });
    onMounted(() => {
      nextTick(() => {
        qrCode = new QRCodeStyling(qrCodeProps(props.url));
        qrCode.append(qrCodeRef.value);
      });
    });

    return {
      qrCodeRef
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';

.qrcode {
  position: relative;
  margin: auto;
  padding: 10px;
  width: 320px;
  background-color: $white;
  border-radius: 20px;
}

</style>
