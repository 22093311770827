import List from '@/pages/Product/List';

export default [
  {
    name: 'product-list',
    path: '/product/list',
    component: List,
    meta: { auth: true }
  },
]
