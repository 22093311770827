<template>
  <div class="page">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" class="list padded">
      <SubHeader :title="`${person?.first}'s List`" :back="false" />
      <Wish
        v-for="item in list" :key="item.id"
        :id="item.id"
        :url="item.url"
        :media="item.media"
        :name="item.name"
        :price="item.price"
        :discount="item.discount"
        :variants="item.variants"
        :variant="item.variant"
        :quantity="item.quantity"
        :notes="item.notes"
        :vendor="item?.vendor?.name"
        :logo="item?.vendor?.logo"
        :logo-background-color="item?.vendor?.logoBackgroundColor"
        :shareable="false"
        :removable="false"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { identity, opt } from 'ku4es-kernel';
import profileImage from '@/capabilities/profileImage';
import { usePersonService } from '@/services/person';
import { useWishService } from '@/services/wish';
import Spinner from '@/components/Spinner';
import SubHeader from '@/components/SubHeader';
import Wish from '@/components/Wish';

export default {
  components: {
    Spinner,
    SubHeader,
    Wish,
  },
  props: [ 'pid' ],
  setup(props) {
    const personService = usePersonService();
    const wishService = useWishService();

    const mounting = ref(false);
    const person = ref({});
    const list = ref([]);

    const personImage = computed(() => profileImage(props.pid, identity.uid()))

    onMounted(() => ( async () => {
      mounting.value = true;
      person.value = {};
      list.value = [];
      const [
        personData,
        wishListData
      ] = await Promise.all([
        personService.read(props.pid),
        wishService.list(props.pid),
      ]);
      person.value = personData
      list.value = wishListData ;
      mounting.value = false;
    })());

    return {
      opt,
      mounting,
      person,
      list,
      personImage,
    }
  }
}
</script>
