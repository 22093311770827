<template>
  <div class="intro item">
    <div class="main-content">
      <Media :list="media" />
      <div class="price-container">
        <span class="price" :class="`${isDiscounted ? ' sale' : ''}`">
            {{Money.tryParse(salePrice(price, discount))}}
          </span>
        <div v-if="isDiscounted" class="original-price">{{(Money.tryParse(price) || '').toString()}}</div>
        <div v-if="isDiscounted" class="discount-info">
          <span>{{discount.name}}</span>
          <span>{{discount.code}}</span>
          <span>{{discount.description}}</span>
        </div>
      </div>
      <div class="controls">
        <button class="control add" :class="{ loading: creating}" @click="handleAddWish" :disabled="isAdded"></button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { Assert, Money } from 'ku4es-kernel';
import { useWishStore } from '@/stores/wish';
import { useWishService } from '@/services/wish';
import salePrice from '@/capabilities/salePrice';
import Media from '@/components/Media';

const props = defineProps(['url', 'media', 'name', 'price', 'discount', 'logo']);

const wishStore = useWishStore();
const wishService = useWishService();

const creating = ref(false);

const isDiscounted = computed(() => props.discount?.price || props.discount?.operation);
const isAdded = computed(() => Assert.exists(wishStore.items.find(i => i.url === props.url)));

const handleAddWish = async () => {
  creating.value = true;
  await wishService.create({ url: props.url, quantity: 1 });
  creating.value = false;
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins/button';

img {
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
  z-index: 1;
}

img.background-image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: blur(16px);
  -webkit-filter: blur(16px);
}

.intro.item .control.add {
  right: 8px;
}

</style>
