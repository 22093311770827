<template>
  <div class="page">
    <SubHeader title="Menu" />
    <div class="content">
      <MenuPanel v-if="isAdmin" title="Admin">
        <router-link :to="{ name: 'config' }">Config</router-link>
      </MenuPanel>
      <MenuPanel title="Profile">
        <div v-if="isSwitched">
          Logged in as {{ account.first }} {{ account.last }}
          <br />
          <button @click="handleSwitch">Exit</button>
        </div>
        <ku4-form ref="ku4ProfileFormRef" @validate="handleProfileValidate">
          <form ref="profileFormRef" class="profile-form">
            <div class="profile-info">
              <div class="profile-image-container">
              <ku4-preview for="profileImage" class="profile-image" :src="accountImage" :altSrc="noImage"></ku4-preview>
              <input id="profileImage" name="image" type="file" class="profile-image-button" />
              <div class="profile-image-button-container">
              </div>
            </div>
              <div class="profile-fields">
                <div class="field">
                  <label for="profileFirst">First:</label>
                  <input
                      id="profileFirst"
                      name="first"
                      type="text"
                      autocomplete="on"
                      autocapitalize="on"
                      placeholder="First Name"
                      :value="account.first"
                    />
                </div>
                <div class="field">
                  <label for="profileMiddle">Middle:</label>
                  <input
                    id="profileMiddle"
                    name="middle"
                    type="text"
                    autocomplete="on"
                    autocapitalize="on"
                    placeholder="Middle Name (optional)"
                    :value="account.middle"
                  />
                </div>
                <div class="field">
                  <label for="profileLast">Last:</label>
                  <input
                    id="profileLast"
                    name="last"
                    type="text"
                    autocomplete="on"
                    autocapitalize="on"
                    placeholder="Last Name"
                    :value="account.last"
                  />
                </div>
                <div class="field">
                  <ku4-mask for="profileDob" template="mm/dd/yyyy" char="[mdy]"></ku4-mask>
                  <label for="profileDob">Birthday:</label>
                  <input
                    id="profileDob"
                    name="dob"
                    type="text"
                    autocomplete="on"
                    inputmode="numeric"
                    placeholder="Birthday"
                    :value="account?.dob && DayPoint.parse(account.dob).toString('mm/dd/yyyy')"
                  />
                </div>
                <div class="validation-container">
                  <ku4-validation
                      for="profileFirst"
                      .pattern="required(regex.name)"
                  >
                    First name can only contain letters.
                  </ku4-validation>
                  <ku4-validation
                      for="profileMiddle"
                      .pattern="optional(regex.name)"
                  >
                    Middle can only container letters.
                  </ku4-validation>
                  <ku4-validation
                      for="profileLast"
                      .pattern="required(regex.name)"
                  >
                    Last can only contain letters.
                  </ku4-validation>
                  <ku4-validation
                      for="profileDob"
                      .pattern="required(regex.any)"
                  >
                    Valid birthday is required.
                  </ku4-validation>
                </div>
              </div>
            </div>
            <div class="form-controls">
              <button type="submit" class="secondary" :disabled="updatingProfile">
                {{updatingProfile ? '' : 'Update'}}
              </button>
            </div>
          </form>
        </ku4-form>
      </MenuPanel>
      <MenuPanel title="Notifications">
        <button class="secondary" @click="handleRequestPermission">Request Permission</button><br/>
        <button class="secondary" :class="{loading:subscribing}" @click="handleSubscribe">Subscribe</button>
      </MenuPanel>
      <MenuPanel v-if="!account.isProxy" title="Child Accounts">
        <div v-if="listingWards" class="spinner" />
        <div>
          <div class="ward-list">
          <Ward
              v-for="ward in wardList"
              :key="ward.pid"
              :pid="ward.pid"
              :image="profileImage(ward.pid)"
              :first="ward.first"
              :middle="ward.middle"
              :last="ward.last"
              :switching="switchingWard === ward.pid"
              @switch="handleSwitchWard"
          />
        </div>
          <div class="create-ward">
          <button class="create-ward-control" v-if="!wardOpen" @click="handleWardOpen">
            + Create new child account
          </button>
          <ku4-panel :open="wardOpen">
            <div class="create-ward-form">
              <ku4-form>
                <form ref="wardFormRef">
                  <div class="field">
                    <ku4-validation
                      for="wardFirstName"
                      pattern=".+"
                    >
                      Required
                    </ku4-validation>
                    <ku4-label for="wardFirstName">
                      <input id="wardFirstName" name="first" type="text" placeholder="First name" />
                    </ku4-label>
                  </div>
                  <div class="field">
                    <ku4-validation
                      for="wardMiddleName"
                      pattern=".*"
                    >
                    </ku4-validation>
                    <ku4-label for="wardMiddleName">
                      <input id="wardMiddleName" name="middle" type="text" placeholder="Middle name" />
                    </ku4-label>
                  </div>
                  <div class="field">
                    <ku4-validation
                      for="wardLastName"
                      pattern=".+"
                    >
                      Required
                    </ku4-validation>
                    <ku4-label for="wardLastName">
                      <input id="wardLastName" name="last" type="text" placeholder="Last name" />
                    </ku4-label>
                  </div>
                  <div class="field">
                    <ku4-validation
                      for="wardDob"
                      pattern=""
                    >
                      Required
                    </ku4-validation>
                    <ku4-mask for="wardDob" template="mm/dd/yyyy" char="[mdy]"></ku4-mask>
                    <ku4-label for="wardDob">
                      <input
                        id="wardDob"
                        name="dob"
                        type="text"
                        inputmode="numeric"
                        placeholder="Birthday"
                      />
                    </ku4-label>
                  </div>
                  <div class="controls">
                    <button type="submit" class="secondary" :class="{ loading: creatingWard }">
                      {{ creatingWard ? '' : 'Create' }}
                    </button>
                    <button type="reset" class="tertiary" @click="handleWardClose">Cancel</button>
                  </div>
                </form>
              </ku4-form>
            </div>
          </ku4-panel>
        </div>
        </div>
      </MenuPanel>
      <MenuPanel title="Share your list">
        <p class="note">
          Copy your public list link below to share your list
          with others who aren't yet on Gifthorse.
        </p>
        <div>
          <textarea
              ref="publicUrlRef"
              class="pubic-url"
              :value="`${origin}/public/wish/list/${account.pid}`"
              @click="handleCopyPublicUrl"
          />
          <button
              ref="publicUrlButtonRef"
              @click="handleCopyPublicUrl"
          >
            Copy
          </button>
        </div>
      </MenuPanel>
      <MenuPanel title="Settings">
        <h3>Filters</h3>
        <p class="note">
          Turn on the filters below to filter the products that will
          be listed in your news feed. Turn off all filters
          to view all products.
        </p>
        <ku4-form ref="ku4FilterFormRef">
          <form ref="filterFormRef" class="filter-form">
            <MenuFilter value="men" @change="filterFormRef.submit()" />
            <MenuFilter value="women" @change="filterFormRef.submit()" />
            <MenuFilter value="children" @change="filterFormRef.submit()" />
            <MenuFilter value="toys" @change="filterFormRef.submit()" />
            <MenuFilter value="electronics" @change="filterFormRef.submit()" />
            <MenuFilter value="clothing" @change="filterFormRef.submit()" />
          </form>
        </ku4-form>
      </MenuPanel>
      <MenuPanel title="Logout">
        <p class="note">
          !!Warning!! Logging out will prevent you from adding items
          with the "Add to Gifthorse" buttons on your favorite stores.
        </p>
        <button v-if="!isSwitched" @click="handleLogout">Logout</button>
      </MenuPanel>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {DayPoint, identity, wait} from 'ku4es-kernel';
import { requestPermission, subscribe } from 'ku4es-notification/client';
import { wireForm } from 'ku4web-components/vue3';
import regex, { required, optional } from '@/capabilities/regex';
import profileImage from '@/capabilities/profileImage';
import { useAccountStore } from '@/stores/account';
import { useSettingsStore } from '@/stores/settings';
import { useWardStore } from '@/stores/ward';
import { useNotificationService } from '@/services/notification';
import { useAccountService } from '@/services/account';
import { useFollowService } from '@/services/follow';
import { useSettingsService } from '@/services/settings';
import { useSuggestionService } from '@/services/suggestion';
import { useWardService } from '@/services/ward';
import { useWishService } from '@/services/wish';
import SubHeader from '@/components/SubHeader';
import MenuFilter from '@/components/MenuFilter';
import MenuPanel from '@/components/MenuPanel';
import Ward from '@/components/Ward';
import noImage from '@/assets/image/noImageAvailable.png';
import { VAPID_PUBLIC_KEY } from '@/config';

const router = useRouter();
const accountStore = useAccountStore();
const settingsStore = useSettingsStore();
const wardStore = useWardStore();
const accountService = useAccountService();
const followService = useFollowService();
const notificationService = useNotificationService();
const settingsService = useSettingsService();
const suggestionService = useSuggestionService();
const wardService = useWardService();
const wishService = useWishService();

const ku4ProfileFormRef = ref(null);
const profileFormRef = ref(null);
const publicUrlRef = ref(null);
const publicUrlButtonRef = ref(null);
const wardFormRef = ref(null);
const ku4FilterFormRef = ref(null);
const filterFormRef = ref(null);
const updatingProfile = ref(false);
const wardPanel = ref(false);
const wardOpen = ref(false);
const listingWards = ref(false);
const creatingWard = ref(false);
const switchingUser = ref(false);
const switchingWard = ref(false);
const subscribing = ref(false);

const origin = computed(() => window.location.origin);
const account = computed(() => accountStore.account || {});
const accountImage = computed(() => profileImage(accountStore.account?.pid, identity.uid()))
const filters = computed(() => settingsStore.filters || {});
const wardList = computed(() => wardStore.items);
const isAdmin = computed(() => (accountStore.account?.roles || []).includes('ghAdmin'))
const isSwitched = computed(() => accountStore && accountStore?.pid !== accountStore.account?.pid);

const handleCopyPublicUrl = () => {
  navigator.clipboard.writeText(publicUrlRef.value.value);
  publicUrlRef.value.select();
  publicUrlButtonRef.value.textContent = 'Copied';
  wait(3000).then(() => {
    publicUrlButtonRef.value.textContent = 'Copy';
  });
}
const handleWardOpen = () => wardOpen.value = true;
const handleWardClose = () => wardOpen.value = false;
const handleCreateWardFormSubmit = async ({ first, middle, last, dob }) => {
  creatingWard.value = true;
  await wardService.create(first.trim(), middle.trim(), last.trim(), dob.trim());
  creatingWard.value = false;
  return false;
}
const handleSwitchWard = async (pid) => {
  switchingWard.value = pid;
  filterFormRef.value.reset();
  await accountService.switchUser(pid);
  await Promise.all([
    followService.list(),
    settingsService.read(),
    suggestionService.list(),
    wardService.list(),
    wishService.list()
  ]);
  ku4FilterFormRef.value.write(filters.value);
  switchingWard.value = false;
}
const handleSwitch = async () => {
  switchingUser.value = true;
  try {
    await accountService.switchUser(accountStore.pid);
    await Promise.all([
      followService.list(),
      settingsService.read(),
      suggestionService.list(),
      wardService.list(),
      wishService.list()
    ]);
  }
  finally {
    filterFormRef.value.reset();
    ku4FilterFormRef.value.write(filters.value);
    switchingUser.value = false;
  }
}
const handleRequestPermission = () => { requestPermission() }
const handleSubscribe = async () => {
  subscribing.value = true;
  try {
    const {endpoint, keys: {auth, p256dh}} = await subscribe('/sw.js', VAPID_PUBLIC_KEY);
    await notificationService.subscribe({auth, endpoint, p256dh});
  } catch(e) { console.warn(e); }
  subscribing.value = false;
}
const handleFilterFormSubmit = async (data) => {
  await settingsService.update({ filters: data });
  return false;
}
const handleLogout = async () => {
  await accountService.logout();
  await router.push({ name: 'intro' });
}
const handleProfileValidate = (e) => {
  if(e.target === ku4ProfileFormRef.value && e?.detail?.invalid?.length > 0) {
    e.target.focusFirstInvalid();
  }
}
const handleProfileFormSubmit = async (data) => {
  updatingProfile.value = true;
  await Promise.all([
    accountService.updateImage(data),
    accountService.update(data)
  ]);
  updatingProfile.value = false;
  return false;
}

onMounted(() => (async () => {
      wireForm(profileFormRef, handleProfileFormSubmit);
      wireForm(wardFormRef, handleCreateWardFormSubmit);
      wireForm(filterFormRef, handleFilterFormSubmit);
      listingWards.value = true;
      await Promise.all([
        settingsService.read(),
        wardService.list(),
      ]);
      filterFormRef.value && filterFormRef.value.reset();
      ku4FilterFormRef.value && ku4FilterFormRef.value.write(filters.value);
      listingWards.value = false;
    })());
</script>

<style lang="scss" scoped>
@import "@/styles/variables/colors";
@import "@/styles/mixins/icon";

.spinner {
  display: flex;
  justify-content: center;
}

.content {
  margin-bottom: 240px;
}

.profile-image-container {
  position: relative;
  flex: 0 1 140px;
  width: 140px;
  height: 140px;
}

.profile-form {
  .form-controls {
    margin: 1rem 0 0 155px;

    button {
      width: 140px;
    }
  }
}

.profile-image-button-container {
  @include icon('\f303', 14px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid;
  color: $dark-grey;
}

.profile-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.profile-image-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 4;
}

.profile-info {
  display: flex;
  justify-content: flex-start;
}

.profile-fields {
  flex: 0 1 auto;
  margin-left: 1rem;

  label {
    width: 100px;
    color: $dark-grey;
  }

  input {
    height: 1.4rem;
    font-size: 1.1rem;
    border:none;
  }

  .field {
    margin-top: 0.4rem;
    flex-flow: nowrap;
  }

  .validation-container {
    margin-top: 1rem;
    color: $red;

    > * {
      display: none;
      font-size: 0.8rem;

      &[invalid] {
        display: block;
      }
    }
  }
}

.create-ward {
  margin-top: 2rem;
}

.create-ward-control {
  font-size: 1.1rem;
}

.create-ward-form {

  .controls {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    font-size: 1.1rem;

    button {
      flex: 1 0 50%;
    }
  }

}

.filter-form {
  > * {
    margin-top: 1rem;
  }
}

</style>
