<template>
  <div class="page">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" class="list padded">
      <Item
        :url="item.url"
        :media="item.media"
        :name="item.name"
        :price="item.price"
        :discount="item.discount"
        :hide-buy="true"
        :hide-mark="true"
        :adding="adding"
        :added="added"
      />
      <div>
        <a target="_blank" @click="() => handleClick(referrerUrl)">
          Continue shopping
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useTempCodeService } from '@/services/tempCode';
import { useVendorService } from '@/services/vendor';
import { useWishService } from '@/services/wish';
import Spinner from '@/components/Spinner';
import Item from '@/components/Product';

const route = useRoute();
const tempCodeService =useTempCodeService();
const vendorService = useVendorService();
const wishService = useWishService();

const mounting = ref(false);
const adding = ref(true);
const added = ref(false);
const referrerUrl = ref('');
const item = ref({});

let navigating = false;
const handleClick = (url) => {
  if(navigating) return;
  navigating = true;
  const windowReference = window.open('about:blank', '_blank');
  tempCodeService.create(url)
    .then(({ tempCode }) => {
      windowReference.location = `${url}${/\?/.test(url) ? '&' : '?' }ghtmpc=${tempCode}`;
      navigating = false;
    });
  return false;
}

onMounted(() => (async () => {
  try {
    const { values, referrer } = route.query;
    item.value = JSON.parse(atob(values.toString()));
    await wishService.add(values);
    added.value = true;
    referrerUrl.value = referrer;
  }
  finally {
    adding.value = false;
  }
})());
</script>

<style lang="scss" scoped>
@import '@/styles/classes';
</style>
