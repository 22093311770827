import { defineStore } from 'pinia';
import { Assert } from 'ku4es-kernel';

export const useProductStore = defineStore('product', {
  state: () => ({
    products: {},
    items: []
  }),
  getters: {
    contains() {
      return (skip, limit) => Assert.exists(this.items[skip + limit - 1]);
    },
    list() {
      return (skip, limit) => this.items[skip + limit - 1];
    }
  },
  actions: {
    update(items = []) {
      const newProducts = items.reduce((a, v) => (a[v.url] = v, a), {});
      this.products = {...this.products, ...newProducts}
      this.items = Object.values(this.products);
    },
    reset() { this.$reset() }
  },
})
