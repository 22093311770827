<template>
  <div class="page">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" class="follow-container">
      <div class="sticky">
        <Search />
      </div>
      <div v-if="Assert.isEmpty(list)" class="no-items short">
        Follow someone
      </div>
      <div class="list padded">
        <Item
          v-for="item in list" :key="item.pid"
          :pid="item.pid"
          :image="profileImage(item.pid)"
          :first="item.first"
          :middle="item.middle"
          :last="item.last"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted} from 'vue';
import { Assert } from 'ku4es-kernel';
import profileImage from '@/capabilities/profileImage';
import {useFollowStore} from '@/stores/follow';
import {useFollowService} from '@/services/follow';
import Spinner from '@/components/Spinner';
import Search from '@/components/Search';
import Item from '@/components/Follow';

export default {
  components: {
    Item,
    Search,
    Spinner
  },
  setup() {
    const followStore = useFollowStore();
    const followService = useFollowService();
    const mounting = ref(false);

    const list = computed(() => followStore.items);

    onMounted(() => ( async () => {
      mounting.value = !list.value || Assert.isEmpty(list.value);
      await followService.list();
      mounting.value = false;
    })());

    return {
      Assert,
      profileImage,
      mounting,
      list,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables/sizes';

.follow-container {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}

.sticky {
  top: 40px;
}

</style>
