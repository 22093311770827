import { defineStore } from 'pinia'
import { DayPoint } from 'ku4es-kernel'

export const useWishStore = defineStore('wish', {
  state: () => ({
    wishes: {},
  }),
  getters: {
    items() {
      return Object.values(this.wishes)
        .sort((a, b) => new Date(b.created) - new Date(a.created));
    }
  },
  actions: {
    list(items = []) {
      const wishes = items.reduce((a, v) => (a[v.id] = v, a), {});
      this.$patch({ wishes });
    },
    create(wish) {
      this.wishes[wish.id] = wish;
    },
    remove(wish) {
      delete this.wishes[wish.id];
    },
    update(wish) {
      this.wishes[wish.id] = { ...this.wishes[wish.id], wish };
    },
    reset() { this.$reset() }
  },
})
