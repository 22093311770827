<template>
  <div class="item suggest" :class="{ suggesting, suggested }" @click="handleSuggest">
    <AspectImage class="suggest-image" :src="image" :alt="`${first},${last}`" />
    <div class="name">
      <span v-if="first">{{first}}&nbsp;</span>
      <span v-if="middle">{{middle}}&nbsp;</span>
      <span>{{last}}</span>
      <div class="status">
        {{ suggesting ? 'Suggesting...' : suggested ? 'Suggested' : 'status' }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUpdated } from 'vue';
import { Assert, Money } from 'ku4es-kernel';
import { useSuggestionStore } from '@/stores/suggestion';
import { useSuggestionService } from '@/services/suggestion';
import noImage from '@/assets/image/noImageAvailable.png';
import AspectImage from '@/components/AspectImage';

export default {
  components: {AspectImage},
  props: [
    'pid', 'image', 'first', 'middle', 'last', 'url'
  ],
  setup(props) {
    const suggestionStore = useSuggestionStore()
    const suggestionService = useSuggestionService();
    const suggesting = ref(false);
    const suggested = ref(false);
    let response = {};

    const handleSuggest = async () => {
      if(suggested.value) { return }
      suggesting.value = true;
      const suggestion = suggestionStore.marked;
      response = await suggestionService.create({ pid: props.pid, url: suggestion.url });
      suggesting.value = false;
    }

    onUpdated(() => {
      suggested.value = !Assert.exists(response.error) && props.url === response.url;
    })

    return {
      noImage,
      Money,
      suggesting,
      suggested,
      handleSuggest
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables/colors';

.item.suggest {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 100%;
  width: 100%;
  height: 80px;

  .suggest-image {
    width: 60px;
  }

  .name {
    margin-left: 1rem;
    font-size: 1.1rem;
  }

  .status {
    font-size: 0.8rem;
    visibility: hidden;
    color: $dark-grey;
  }

  &.suggesting {
    .status {
      visibility: visible;
    }
  }

  &.suggested {
    .status {
      visibility: visible;
    }
  }
}

</style>
