import List from '@/pages/Vendor/List';

export default [
  {
    name: 'vendor-list',
    path: '/vendor/list',
    component: List,
    meta: { auth: true }
  },
]
