import { defineStore } from 'pinia'

export const usePersonStore = defineStore('person', {
  state: () => ({
    persons: {},
  }),
  getters: {
    items() {
      return Object.values(this.persons);
    }
  },
  actions: {
    list(items = []) {
      const persons = items.reduce((a, v) => (a[v.pid] = v, a), {});
      this.$patch({ persons });
    },
    reset() { this.$reset() }
  },
})
