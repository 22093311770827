<template>
  <div :class="`overlay${ searchList.length > 0 && queryLength > 4 ? '' : ' hide'}`"></div>
  <div ref="searchRef">
    <form>
      <ku4-label for="query">
        <div class="query-input" :class="{ loading }">
          <input
            ref="inputRef"
            id="query"
            name="query"
            type="text"
            placeholder="Search"
            @keyup="handleChange"
            @change="handleChange"
          />
        </div>
      </ku4-label>
    </form>
    <div :class="`query-results${ searchList.length > 0 && queryLength > 4 ? '' : ' hide'}`">
      <div v-for="item in searchList" :key="item.pid" class="query-result">
        <SearchResult
          :pid="item.pid"
          :image="profileImage(item.pid)"
          :first="item.first"
          :middle="item.middle"
          :last="item.last"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { Assert, debounce } from 'ku4es-kernel';
import profileImage from '@/capabilities/profileImage';
import { usePersonStore } from '@/stores/person';
import { useFollowService } from '@/services/follow';
import { usePersonService } from '@/services/person';
import SearchResult from './SearchResult';

export default {
  components: {SearchResult },
  setup() {
    const personStore = usePersonStore();
    const personService = usePersonService();
    const followService = useFollowService();

    const searchRef = ref(null);
    const inputRef = ref(null);
    const previousLength = ref(0);
    const queryLength = ref(0);
    const loading = ref(false);

    const search = debounce((query) => handleSearch(query), 500);
    const searchList = computed(() => personStore.items);

    const handleSearch = async (query) => {
      loading.value = true;
      await personService.list(query);
      loading.value = false;
    }
    const handleFollow = pid => followService.create({ pid });
    const handleChange = e => {
      const query = e.target.value;
      queryLength.value = query.length;
      if(queryLength.value < previousLength.value) {
        previousLength.value = query.length;
        if(queryLength.value < 2) {
          personService.list('');
        }
      }
      else {
        previousLength.value = query.length;
        search(query);
      }
    }
    const handleClickOutside = e => {
      if(!searchRef.value?.contains(e.target)) {
        document.removeEventListener('click', handleClickOutside)
        inputRef.value.value = '';
        queryLength.value = inputRef.value.value.length;
        previousLength.value = inputRef.value.value.length;
      }
    }

    onMounted(() => {
      inputRef.value.addEventListener('focus', () => document.addEventListener('click', handleClickOutside));
    });

    return {
      Assert,
      profileImage,
      searchRef,
      inputRef,
      queryLength,
      loading,
      searchList,
      handleChange,
      handleFollow
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/spinner';

form {
  position: relative;
  z-index: 4;
}

button[disabled] {
  opacity: 0.4;
}

.query-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.2rem 0.6rem;
  border: 1px solid $grey;
  border-radius: 8px;

  &.loading {
    @include spinner(inherit, 12px);
  }

  input {
    border: none;
    font-size: 1rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: $white;
  opacity: 0;
  z-index: 3;
}

.query-results {
  position: absolute;
  width: 100%;
  padding: 0 0.4rem;
  border: 1px solid $grey;
  background: $white;
  box-shadow: 0 6px 9px -3px $dark-grey;
  z-index: 4;
}

.overlay.hide,
.query-results.hide {
  display: none;
}

.query-result {
  border-bottom: 1px solid $dark-grey;

  &:last-child {
    border-bottom: none;
  }
}

.search {
  display: none;
}

.search.searching {
  display: inline-block;
}

</style>
