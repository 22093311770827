<template>
  <div class="page">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" class="list padded">
      <div
        v-if="browserExtensionLink && isBrowserExtensionInstalled === false"
        class="browser-extension-message"
      >
        <img :src="logo" alt="gifthorse logo" />
        <span>
          Install and activate the
          <a :href="browserExtensionLink" target="_blank">
            Gifthorse Browser Extension
          </a>
          to add items to your list from all of the stores listed.
        </span>
      </div>
      <ul>
        <li v-for="vendor in list" :key="vendor.domain">
          <a target="_blank" @click="() => handleClick(vendor.url)" :style="{ backgroundColor: vendor.backgroundColor }">
            <img :src="vendor.logo" :alt="vendor.domain" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { Assert, wait } from 'ku4es-kernel';
import { useVendorStore } from '@/stores/vendor';
import { useTempCodeService } from '@/services/tempCode';
import { useVendorService } from '@/services/vendor';
import logo from '@/assets/image/logo_pink.png';
import Spinner from '@/components/Spinner';

const vendorStore = useVendorStore();
const tempCodeService = useTempCodeService();
const vendorService = useVendorService();
const mounting = ref(false);
const isBrowserExtensionInstalled = ref(null);

const list = computed(() => vendorStore.items);
const browserExtensionLink = computed(() => {
  if(/apple/i.test(navigator.vendor)) { return 'https://www.apple.com/app-store/'; }
  if(/google/i.test(navigator.vendor)) { return 'https://chrome.google.com/webstore/category/extensions'; }
  return null;
});

let navigating = false;
const handleClick = (url) => {
  if(navigating) return;
  navigating = true;
  const windowReference = window.open('about:blank', '_blank');
  tempCodeService.create(url)
    .then(({ tempCode }) => {
      windowReference.location = `${url}?ghtmpc=${tempCode}`;
      navigating = false;
    });
  return false;
}

onMounted(() => ( async () => {
  mounting.value = !list.value || Assert.isEmpty(list.value);
  await vendorService.list();
  mounting.value = false;
  wait(1000).then(() =>
    isBrowserExtensionInstalled.value = Assert.exists(document.getElementById('gifthorseExtension'))
  )
})());
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';

ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 10px;
}

img {
  object-fit: contain;
  width: 100%;
  height: 100px;
}

.browser-extension-message {
  position: sticky;
  display: flex;
  flex-direction: row;
  top: 66px;
  margin: 0 1rem;
  padding: 1rem;
  background: $white;
  border-radius: 8px;
  border: 4px solid $grey;

  img {
    flex: 0 1;
    height: 44px;
  }

  span {
    flex: 1 0;
    margin-left: 1rem;
  }
}

</style>
