<template>
  <div class="page intro-list">
    <div v-if="mounting" class="loading-container">
      <Spinner />
    </div>
    <div v-if="!mounting" ref="listRef" class="list padded">
      <Item
        v-for="item in list" :key="item.url"
        :url="item.url"
        :media="item.media"
        :name="item.name"
        :price="item.price"
        :discount="item.discount"
        :logo="item?.vendor?.logo"
        :logo-background-color="item?.vendor?.backgroundColor"
      />
      <div ref="tailRef" class="tail">
        <div v-if="listing" class="spinner" />
      </div>
    </div>
    <ku4-modal ref="ku4ModalRef">
      <div class="product-modal">
        <div>
          <router-link :to="{ name: 'account-create' }">Create account</router-link>
          or <router-link :to="{ name: 'account-login' }">Login</router-link>
          to add this item to Gifthorse:
        </div>
        <img :src="modalImgSrc" alt="product image" />
        <div class="modal-controls">
          <router-link :to="{ name: 'account-create' }" class="create-account-control">Create account</router-link>
          <router-link :to="{ name: 'account-login' }" class="login-control">Login</router-link>
        </div>
      </div>
    </ku4-modal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUpdated, onUnmounted } from 'vue';
import { Assert, debounce } from 'ku4es-kernel';
import { useTemporaryValuesStore } from '@/stores/temporaryValues'
import { useProductStore } from '@/stores/product'
import { useProductService } from '@/services/product'
import Item from '@/components/IntroItem';
import Spinner from '@/components/Spinner';

const itemsPerPage = 5;
const temporaryValuesStore = useTemporaryValuesStore();
const productStore = useProductStore();
const productService = useProductService();

const ku4ModalRef = ref(null);
const listRef = ref(null);
const tailRef = ref(null);
const page = ref(0);
const mounting = ref(false);
const listing = ref(false);
const modalImgSrc = ref(null);

const list = computed(() => productStore.items);

let intersectionObserver;
let observed = false;

const listProducts = async () => {
  listing.value = true;
  await productService.list(page.value, itemsPerPage);
  page.value = page.value + itemsPerPage + 1;
  listing.value = false;
}

const observe = () => {
  if(!observed && intersectionObserver && tailRef.value) {
    intersectionObserver.observe(tailRef.value);
    observed = true;
  }
}

onMounted(() => ( async () => {
  mounting.value = true;
  await listProducts();
  intersectionObserver = new IntersectionObserver(
    debounce(([{ isIntersecting }]) => {
      !Assert.isEmpty(list.value) && isIntersecting && listProducts();
    }, 800),
    {
      rootMargin: '1000px'
    });
  observe();
  mounting.value = false;
  if(!temporaryValuesStore.isEmpty) {
    const values = JSON.parse(atob(temporaryValuesStore.values));
    console.log(values);
    modalImgSrc.value = values.media[0];
    ku4ModalRef.value.display();
  }
})());

onUpdated(() => {
  observe();
});

onUnmounted(() => {
  if(observed) {
    intersectionObserver && intersectionObserver.disconnect();
    observed = false;
  }
});
</script>

<style lang="scss" scoped>
@import '../styles/variables/sizes';
@import '../styles/variables/colors';
.intro-list {
  width: 100vw;
}

.product-modal {
  padding: 1rem;
  margin: 1rem;
  background-color: $white;
  border-radius: 6px;
  text-align: center;

  div {
    text-align: left;
  }
}
</style>
