import {opt} from 'ku4es-kernel';

/**
 * *
 * @param price {number} - a floating point price.
 * @param discount {{[price]: number, [operation]: string}} - an object
 * literal containing either a `price` or an `operation` key where
 * price is a floating point price and operation is a string where `p`
 * may be used to denote price e.g. 50% off could be achieved by
 * passing `'p * .5'` as the discount string.
 * @returns {number} - a floating point price.
 */
export default (price, discount) => {
  return discount?.price || (
    discount?.operation &&
      opt(
        () => Function('p', `return p = parseFloat(p), ${discount.operation}`)(price),
        price
      )
    ) || price;
}
