import { createRouter, createWebHistory } from 'vue-router';
import { wait } from 'ku4es-kernel';
import account from '@/router/routes/account';
import config from '@/router/routes/config';
import follow from '@/router/routes/follow';
import product from '@/router/routes/product';
import suggestion from '@/router/routes/suggestion';
import vendor from '@/router/routes/vendor';
import wish from '@/router/routes/wish';
import Intro from '@/pages/Intro';
import Menu from '@/pages/Menu';

const scrollPositionCache = {};

export default createRouter({
  history: createWebHistory(),
  routes: [
    ...account,
    ...config,
    ...follow,
    ...product,
    ...suggestion,
    ...vendor,
    ...wish,
    {
      name: 'menu',
      path: '/menu',
      component: Menu,
      meta: {
        auth: true,
        nav: false,
        subHeader: 'Menu'
      }
    },
    {
      name: 'intro',
      path: '/',
      component: Intro,
      meta: { auth: false }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/product/list'
    }
  ],
  scrollBehavior(to, from) {
    const scrollPosition = scrollPositionCache[to.path] || { top: 0 };
    scrollPositionCache[from.path] = { top: document.querySelector('html').scrollTop };
    return wait(200).then(() => scrollPosition);
  }
});
