import Login from '@/pages/Account/Login';
import Create from '@/pages/Account/Create';
import PasswordReset from '@/pages/Account/PasswordReset';
import PasswordChange from '@/pages/Account/PasswordChange';

export default [
  {
    name: 'account-login',
    path: '/account/login',
    component: Login,
    meta: { auth: false }
  },
  {
    name: 'account-create',
    path: '/account/create',
    component: Create,
    meta: { auth: false }
  },
  {
    name: 'password-reset',
    path: '/account/password/reset',
    component: PasswordReset,
    meta: { auth: false }
  },
  {
    name: 'password-change',
    path: '/account/password/change/:token',
    component: PasswordChange,
    props: true,
    meta: { auth: false }
  },
]
