import { Assert } from 'ku4es-kernel';
import { useSuggestionStore } from '@/stores/suggestion';
import Suggest from '@/pages/Suggestion/Suggest';

export default [
  {
    name: 'suggestion-suggest',
    path: '/suggestion/suggest',
    component: Suggest,
    beforeEnter: () => {
      const suggestionStore = useSuggestionStore();
      return Assert.exists(suggestionStore.marked) || { name: 'product-list' }
    },
    meta: { auth: true }
  },
]
