<template>
  <div ref="introHeaderRef" class="intro header">
    <router-link :to="{ name: 'intro' }">
      <div class="intro-image-container">
        <img :src="introLogo" alt="Gifthorse" class="intro-logo">
      </div>
    </router-link>
    <div v-if="displayControls" class="subheader controls">
      <router-link :to="{ name: 'account-create' }">Create account</router-link>
      <router-link :to="{ name: 'account-login' }">Login</router-link>
    </div>
  </div>
  <div ref="introMiniHeaderRef" class="intro-mini-header">
    <img :src="introLogoPink" alt="Gifthorse" class="intro-logo">
    <div v-if="displayControls" class="controls">
      <router-link :to="{ name: 'account-create' }">Sign up</router-link>
      <router-link :to="{ name: 'account-login' }">Login</router-link>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted, onUpdated, onUnmounted} from 'vue';
import { useRoute } from 'vue-router';
import introLogo from '@/assets/image/intro_logo.png';
import introLogoPink from '@/assets/image/intro_logo_pink.png';

export default {
  setup() {
    const route = useRoute();
    const introHeaderRef = ref(null);
    const introMiniHeaderRef = ref(null);

    const displayControls = computed(() =>
        route.path === '/' ||
        /\/public\/wish\/list\//.test(route.path)
    )

    let intersectionObserver;
    let observed = false;

    const observe = () => {
      if(!observed && intersectionObserver && introHeaderRef.value) {
        intersectionObserver.observe(introHeaderRef.value);
        observed = true;
      }
    }

    onMounted( () => (async () => {
      intersectionObserver = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if(isIntersecting) { introMiniHeaderRef.value.classList.remove('show'); }
          else { introMiniHeaderRef.value.classList.add('show'); }
        },
        {
          rootMargin: '115px',
          threshold: 1.0
        }
      );
      observe();
    })());

    onUpdated(() => {
      observe();
    });

    onUnmounted(() => {
      intersectionObserver && intersectionObserver.disconnect();
      observed = false;
    });

    return {
      displayControls,
      introLogo,
      introLogoPink,
      introHeaderRef,
      introMiniHeaderRef,
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';

.intro.header {
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 5;

  .intro-image-container {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    background-color: $pink;

    .intro-logo {
      width: auto;
      height: 82px;
      object-fit: contain;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: $white;
    border-bottom: 2px solid $pink;

    > * {
      text-decoration: none;
      color: $dark-grey;
    }
  }
}
.intro-mini-header,
.public-mini-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.5rem 1rem;
  background: $white;
  z-index: 3;

  img {
    height: 36px;
  }

  .controls {
    display: flex;
    flex: 0 1 140px;
    justify-content: space-between;

    > * {
      text-decoration: none;
      color: $dark-grey;
    }
  }
}
.intro-mini-header {
  opacity: 0;
  transition: opacity 0s;

  &.show {
    position: fixed;
    opacity: 1;
    transition: opacity 0.4s;
  }
}

.public-mini-header .controls * {
  display: inline-flex;
}
</style>
