import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', {
  state: () => ({
    config: {},
  }),
  getters: {
    paths: (state) => Object.keys(state.config),
    pathConfig: (state) => (path) => state.config[path],
  },
  actions: {
    setConfig(config) {
      this.$patch({ config });
    },
    updateConfig({ path, config }) {
      this.config[path] = config;
    },
    reset() { this.$reset() }
  },
})
