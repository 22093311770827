<template>
  <nav class="navigation">
    <router-link class="products" :to="{ name: 'product-list' }">
      <div class="icon" />
      <span class="title">News</span>
    </router-link>
    <router-link class="vendors" :to="{ name: 'vendor-list' }">
      <div class="icon" />
      <span class="title">Shop</span>
    </router-link>
    <router-link class="wishes" :to="{ name: 'wish-list' }">
      <div class="icon" />
      <span class="title">Wishes</span>
    </router-link>
    <router-link class="follows" :to="{ name: 'follow-list' }">
      <div class="icon" />
      <span class="title">Follows</span>
    </router-link>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/button';
@import '@/styles/mixins/icon';

.navigation {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0.4rem 1rem 1.4rem;
  background-color: $white;
  z-index: 2;

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 auto;
    width: 36px;
    height: 36px;
    margin: 0 0 0 1.6rem;
    padding: 0.4rem;
    border: none;
    font-size: 0.6rem;
    text-decoration: none;
    color: $dark-grey;

    &:first-child {
      margin: 0;
    }

    &.products {
      .icon {
        @include icon('\f03a', 24px);
      }
    }

    &.follows {
      .icon {
        @include icon('\f0c0', 24px);
      }
    }

    &.vendors {
      .icon {
        @include icon('\f290', 24px);
      }
    }

    &.wishes {
      padding: 0;
      .icon {
        @include icon-img('@/assets/image/logo_dark-grey.png', 22px);
        flex: 1 0 auto;
        width: 32px;
        height: 32px;
      }

      &[class*="active"] {
        .icon {
          @include icon-img('@/assets/image/logo_pink.png', 22px);
        }
      }
    }

    &.ar {
      @include icon('\f06b', 24px);
    }

    &[class*="active"] {
      color: $pink;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        border: 2px solid #fff;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        margin: -15px 0 0 -15px;
      }
    }
  }
}

.standalone .navigation {
  padding-bottom: 2.4rem;
}

</style>
