import { defineStore } from 'pinia'

export const useWardStore = defineStore('ward', {
  state: () => ({
    wards: [],
  }),
  getters: {
    items() {
      return Object.values(this.wards);
    }
  },
  actions: {
    list(items = []) {
      const wards = items.reduce((a, v) => (a[v.pid] = v, a), {});
      this.$patch({ wards });
    },
    create(ward) {
      this.wards[ward.pid] = ward;
    },
    remove(ward) {
      delete this.wards[ward.pid];
    },
    reset() { this.$reset() }
  },
})
