export default {
  any: /[\S\s]+/,
  username: /^\w+([.\w-+]+)?@\w+([.\w-]+)?\.[A-Za-z0-9]{2,}$/,
  password: /^.{8,16}$/,
  email: /^\w+([.\w-+]+)?@\w+([.\w-]+)?\.[A-Za-z0-9]{2,}$/,
  name: /[A-z\s.-]+/,
}

export const required = (pattern) => `^${pattern.toString().slice(1, -1)}$`
export const optional = (pattern) => `(^$)|(${required(pattern)})`;
