import { Assert } from 'ku4es-kernel';
import router from '@/router';
import { useTemporaryValuesStore } from '@/stores/temporaryValues';
import { useAccountService } from '@/services/account';

const storeProductValues = () => {
  const temporaryValuesStore = useTemporaryValuesStore();
  /**
   * Store "values" from query string if they exist.
   * This should be a base64 value that represents a product
   * that a user is trying to add. We store it here so that
   * the user can login in or sign up and then have the value
   * added before they are sent back to the referrer URL.
   */
  const url = new URL(location.href);
  const queryString = new URLSearchParams(url.search);
  const values = queryString.get('values');
  const referrer = queryString.get('referrer');
  values && temporaryValuesStore.setValues(values);
  referrer && temporaryValuesStore.setReferrer(referrer);
}

router.beforeEach(async (to, from, next) => {
  const accountService = useAccountService();
  const token = (
    accountService.read('token') ||
    (await accountService.load()).read('token')
  );

  const account = accountService.read('account');
  const isAdmin = account && (account.roles || []).includes('ghAdmin');
  const isAuthenticated = Assert.exists(token);

  if(to?.meta?.auth === true) {
    if(isAuthenticated) {
      // Connect websocket??
      if(to?.meta?.admin === true) {
        isAdmin ? next() : next({name: 'product-list'});
      }
      else {
        to.path === '/' ? next({name: 'product-list'}) : next();
      }
    }
    else {
      storeProductValues();
      next({ path: '/' })
    }
  }
  else if(isAuthenticated) {
    // Connect websocket??
    to.path === '/' ? next({ name: 'product-list' }) : next();
  }
  else {
    storeProductValues();
    next();
  }
});
