import request, { GET } from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { usePersonStore } from '@/stores/person';
import { api } from '@/config';
const domain = api();

class PersonService {

  constructor(accountStore, personStore) {
    this._accountStore = accountStore;
    this._personStore = personStore;
  }

  async list(query) {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/person/list`, { query }, token);
    if(success(response)) {
      const items = await response.json();
      this._personStore.list(items);
      return items;
    }
  }

  async read(pid) {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/person/${pid}`, null, token);
    return await response.json();
  }

}

let instance;
export const usePersonService = () =>
  instance || (instance = new PersonService(useAccountStore(), usePersonStore()), instance)
