<template>
  <div class="page" :class="`component${email ? ' sent' : ''}`">
    <div class="instructions">
      Enter a valid email address and we will send you a password reset link.
      <ku4-form>
        <form ref="formRef">
          <div class="field">
            <ku4-validation for="recover" pattern="^\w+([.\w-+]+)?@\w+([.\w-]+)?\.[A-Za-z0-9]{2,}$">
              Email is required.
            </ku4-validation>
            <ku4-label for="recover">
              <input
                  id="recover"
                  name="recover"
                  type="text"
                  placeholder="Email"
                  autocomplete="on"
                  autocorrect="off"
                  autocapitalize="none"
              />
            </ku4-label>
          </div>
          <div class="form-controls">
            <button type="submit" class="primary" :class="{ loading: formSubmitting }">
              {{ formSubmitting ? '' : 'Submit' }}
            </button>
            <router-link class="tertiary" :to="{ name: 'account-login' }">
              Cancel
            </router-link>
          </div>
        </form>
      </ku4-form>
    </div>
    <div class="success">
      Password recovery instructions will be sent to
      <span class="email">{{email}}</span>
      if it is a valid address for this account.
      <button type="button" class="primary" slide="2" @click="handleAcknowledge">Ok</button>
    </div>
  </div>
</template>

<script>

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { wireForm } from 'ku4web-components/vue3';
import { useAccountService } from '@/services/account';

export default {
  setup() {
    const router = useRouter();
    const accountService = useAccountService();
    const formRef = ref(null);
    const email = ref('');
    const formSubmitting = ref(false);

    const handleSubmit = async ({ recover }) => {
      try {
        formSubmitting.value = true;
        await accountService.retrieveRecoveryToken(recover);
        email.value = recover;
      }
      finally {
        formSubmitting.value = false;
      }
      return false;
    }

    const handleAcknowledge = () => {
      formRef.value.reset()
      email.value = '';
      router.push({ name: 'intro' });
    }

    onMounted(() => {
      wireForm(formRef, handleSubmit);
    });

    return {
      formRef,
      email,
      formSubmitting,
      handleAcknowledge
    }
  }
}

</script>

<style lang="scss" scoped>

.component .instructions, {
  margin-top: 4rem;
}

.success {
  display: none;
}

.sent form {
  display: none;
}

.sent .instructions {
  display: none;
}

.sent .success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 4rem auto
}

.email {
  display: block;
  margin: 0.8rem 0;
  font-weight: 600;
}

.form-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem auto;
  max-width: 360px;

  a, button {
    flex: 0 1 48%;
    width: auto;
    margin-top: 1rem;
  }
}
</style>
