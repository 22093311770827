import request, { GET } from '@/capabilities/request';
import { api } from '@/config';
import {useAccountStore} from '@/stores/account';
const domain = api();

class TempCodeService {

  constructor(accountStore) {
    this._accountStore = accountStore;
  }

  async create(url) {
    const { token } = this._accountStore;
    const origin = (new URL(url)).hostname.split('.').slice(-2).join('.');
    const { response } = await request(GET, `${domain}/tempcode?domain=${origin}`, null, token);
    return await response.json();
  }

}

let instance;
export const useTempCodeService = () =>
  instance || (instance = new TempCodeService(useAccountStore()), instance)
