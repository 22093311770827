import { defineStore } from 'pinia'

export const useFollowStore = defineStore('follow', {
  state: () => ({
    follows: {},
  }),
  getters: {
    items() {
      return Object.values(this.follows);
    }
  },
  actions: {
    list(items = []) {
      const follows = items.reduce((a, v) => (a[v.pid] = v, a), {});
      this.$patch({ follows });
    },
    create(follow) {
      this.follows[follow.pid] = follow;
    },
    remove(follow) {
      delete this.follows[follow.pid];
    },
    reset() { this.$reset() }
  },
})
