<template>
  <div class="item" :data-index="index">
    <div class="main-content">
      <Media :list="media" />
      <div v-if="logo" class="logo" :style="{ backgroundColor: logoBackgroundColor || '#fff' }">
        <img :src="logo" :alt="vendor" />
      </div>
      <div class="price-container">
        <span class="price" :class="`${isDiscounted ? ' sale' : ''}`">
          {{Money.tryParse(salePrice(price, discount))}}
        </span>
        <div v-if="isDiscounted" class="original-price">{{(Money.tryParse(price) || '').toString()}}</div>
        <div v-if="isDiscounted" class="discount-info">
        <span>{{discount.name}}</span>
        <span>{{discount.code}}</span>
        <span>{{discount.description}}</span>
      </div>
      </div>
      <div v-if="!hideControls" class="controls">
        <a v-if="!hideBuy" class="control buy" :href="url" target="_blank"></a>
        <button v-if="!hideMark" class="control share" @click="handleMarkProduct"></button>
        <button class="control add" :class="{ loading: creating || adding}" @click="handleAddWish" :disabled="isAdded || added"></button>
      </div>
    </div>
    <ku4-grid columns-xs="4" class="meta">
      <ku4-col span-xs="4" class="name">
        <div v-if="vendor" class="vendor">{{vendor}}</div>
        {{name}}
      </ku4-col>
      <ku4-col span-xs="4">
        <ku4-form ref="ku4FormRef">
          <form ref="formRef">
            <fieldset class="item-fields" :disabled="disabled">
              <div class="item-field">
                <label :for="`quantity_${id}`">Quantity</label>
                <input
                  name="quantity"
                  type="number"
                  min="1"
                  :id="`quantity_${id}`"
                  :value="1"
                />
              </div>
              <div v-if="!Assert.isNullOrEmpty(variants)">
                <Variant v-for="([label, values]) in Object.entries(variants)" :key="label"
                  :name="label"
                  :values="values"
                />
              </div>
            </fieldset>
          </form>
        </ku4-form>
      </ku4-col>
    </ku4-grid>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Assert, Money, identity } from 'ku4es-kernel';
import { useWishStore } from '@/stores/wish';
import { useSuggestionService} from '@/services/suggestion';
import { useWishService } from '@/services/wish';
import salePrice from '@/capabilities/salePrice';
import { readProduct } from '@/capabilities/form';
import Media from '@/components/Media';
import Variant from '@/components/Variant';

const id = identity.uid();
const props = defineProps([
  'index', 'url', 'media', 'name', 'price', 'variants', 'discount', 'vendor', 'logo',
  'logoBackgroundColor', 'hideControls', 'hideBuy', 'hideMark', 'adding', 'added', 'disabled'
]);

const router = useRouter();
const wishStore = useWishStore();
const suggestionService = useSuggestionService();
const wishService = useWishService();

const ku4FormRef = ref(null);
const creating = ref(false);

const isDiscounted = computed(() => props.discount?.price || props.discount?.operation);
const isAdded = computed(() => Assert.exists(wishStore.items.find(i => i.url === props.url)));

const handleMarkProduct = () => {
  suggestionService.mark({
    url: props.url,
    media: props.media,
    name: props.name,
    price: props.price,
    discount: props.discount
  });
  router.push({ name: 'suggestion-suggest' });
}
const handleAddWish = async () => {
  creating.value = true;
  const data = await ku4FormRef.value.read();
  await wishService.create({ ...readProduct(data), url: props.url, });
  creating.value = false;
}
</script>
