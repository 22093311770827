import Manage from '@/pages/Config/Manage';

export default [
  {
    name: 'config',
    path: '/admin/config',
    component: Manage,
    meta: { auth: true, admin: true }
  },
]
