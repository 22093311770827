<template>
  <div ref="itemRef" class="item" :class="{ removing }">
    <div class="main-content">
      <Media :list="media" />
      <div v-if="logo" class="logo" :style="{ backgroundColor: logoBackgroundColor || '#fff' }">
        <img :src="logo" :alt="vendor" />
      </div>
      <div class="price-container">
        <span class="price" :class="`${isDiscounted ? ' sale' : ''}`">
            {{ Money.tryParse(salePrice(price, discount)) }}
          </span>
        <div v-if="isDiscounted" class="original-price">{{(Money.tryParse(price) || '').toString()}}</div>
        <div v-if="isDiscounted" class="discount-info">
          <span>{{discount.name}}</span>
          <span>{{discount.code}}</span>
          <span>{{discount.description}}</span>
        </div>
      </div>
      <div class="controls">
        <a class="control buy" :href="url" target="_blank"></a>
        <button v-if="shareable !== false" class="control share" @click="handleMarkProduct"></button>
        <button v-if="removable !== false" class="control remove" :class="{ loading: removing}" @click="handleRemoveWish"></button>
      </div>
    </div>
    <ku4-grid columns-xs="4" class="meta">
      <ku4-col span-xs="4" class="name">
        <div v-if="vendor" class="vendor">{{vendor}}</div>
        {{name}}
      </ku4-col>
      <ku4-col v-if="showForm !== false" span-xs="4">
        <ku4-form ref="ku4FormRef">
          <form ref="formRef">
            <fieldset class="item-fields" :disabled="disabled">
              <div class="item-field">
                <label :for="`quantity_${uid}`">Quantity</label>
                <input
                  name="quantity"
                  type="number"
                  min="1"
                  :id="`quantity_${uid}`"
                  :value="quantity"
                  @change="handleChange"
                />
              </div>
              <div v-if="!Assert.isNullOrEmpty(variants)">
                <Variant v-for="([label, values]) in Object.entries(variants)" :key="label"
                  :name="label"
                  :values="values"
                  @change="handleChange"
                />
              </div>
              <label v-if="!(disabled && !notes)" class="notes-label" :for="`notes_${uid}`">Notes:</label>
              <textarea
                class="notes-content"
                name="notes"
                :id="`notes_${uid}`"
                @change="handleChange"
              >
              </textarea>
            </fieldset>
          </form>
        </ku4-form>
      </ku4-col>
    </ku4-grid>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Assert, Money, identity } from 'ku4es-kernel';
import { wireForm } from 'ku4web-components/vue3';
import removeItem from '@/capabilities/transitions/removeItem';
import salePrice from '@/capabilities/salePrice';
import { readProduct } from '@/capabilities/form';
import { useSuggestionService } from '@/services/suggestion';
import { useWishService } from '@/services/wish';
import Media from '@/components/Media';
import Variant from '@/components/Variant';

const uid = identity.uid();
const props = defineProps([
  'id', 'url', 'media', 'name', 'price', 'variants', 'quantity', 'vendor', 'logo',
  'logoBackgroundColor', 'discount', 'variant', 'notes', 'shareable', 'removable',
  'showForm', 'disabled'
]);

const router = useRouter();
const suggestionService = useSuggestionService();
const wishService = useWishService();

const ku4FormRef = ref(null);
const formRef = ref(null);
const itemRef = ref(null);
const removing = ref(false);

const isDiscounted = computed(() => props.discount?.price || props.discount?.operation);

const handleMarkProduct = () => {
  suggestionService.mark({
    url: props.url,
    media: props.media,
    name: props.name,
    price: props.price,
    discount: props.discount
  });
  router.push({ name: 'suggestion-suggest' })
}
const handleRemoveWish = async () => {
  removing.value = true;
  removeItem(itemRef.value, () => wishService.remove({id: props.id, url: props.url}));
}
const handleChange = async () => {
  await handleSubmit(await ku4FormRef.value.read());
}
const handleSubmit = async (data) => {
  await wishService.update({ ...readProduct(data), id: props.id })
  return false;
}

onMounted(() => {
  if(props.showForm !== false) {
    wireForm(formRef.value, handleSubmit);
    const values = Object.entries(props.variant || {}).reduce((a, [k, v]) => (a[`variant_${k}`] = v, a), {});
    values.notes = props.notes;
    ku4FormRef.value.write(values, true);
  }
});

</script>
