import { defineStore } from 'pinia'

export const useVendorStore = defineStore('vendor', {
  state: () => ({
    vendors: [],
  }),
  getters: {
    items() {
      return Object.values(this.vendors);
    }
  },
  actions: {
    list(items = []) {
      const vendors = items.reduce((a, v) => (a[v.domain] = v, a), {});
      this.$patch({ vendors });
    },
    reset() { this.$reset() }
  },
})
