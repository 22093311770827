<template>
  <SwipeItem ref="itemRef" class="item" :class="{ removing }" :width="40">
    <template #content>
      <div class="info" @click="handleListWishes">
        <AspectImage
          class="follow-image"
          :src="image"
          :alt="`${first},${last}`"
        />
        <div class="name">
          {{first}} {{middle}} {{last}}
        </div>
      </div>
    </template>
    <template #controls>
      <button class="unfollow" @click="handleUnfollow"></button>
    </template>
  </SwipeItem>
</template>

<script>
import {ref} from 'vue';
import { useRouter } from 'vue-router';
import { Assert, Money } from 'ku4es-kernel';
import removeItem from '@/capabilities/transitions/removeItem';
import { useFollowService } from '@/services/follow';
import AspectImage from '@/components/AspectImage';
import SwipeItem from '@/components/SwipeItem';

export default {
  components: {
    AspectImage,
    SwipeItem
  },
  props: [
    'pid', 'image', 'first', 'middle', 'last'
  ],
  setup(props) {
    const router = useRouter();
    const followService = useFollowService();

    const itemRef = ref(null);
    const removing = ref(false);

    const handleListWishes = () => router.push({ name: 'follow-wish-list', params: { pid: props.pid } })
    const handleUnfollow = () => {
      removing.value = true;
      removeItem(itemRef.value.$el, () => followService.remove({ pid: props.pid }));
    }

    return {
      Assert,
      Money,
      itemRef,
      removing,
      handleListWishes,
      handleUnfollow
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colors';
@import '@/styles/mixins/aspect-ratio';
@import '@/styles/mixins/icon';

.item {
  display: flex;
  flex-direction: row;
  flex: 0 1 100%;
  align-items: center;
  height: 80px;
  margin-top: 1rem;

  &.removing {
    margin: 0;
  }
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 auto;
  font-size: 1.1rem;
}

.name {
  margin-left: 1rem;
}

button {
  height: 100%;
  width: 100%;
  margin-left: 1rem;
  padding: 0;

  &.unfollow {
    @include icon('\f00d');
    margin: 0;
    color: $red;
  }
}

@media only screen and (min-width: 787px) {
  .follow-item {
    width: 50vw;
  }
}

@media only screen and (min-width: 1199px) {
  .follow-item {
    width: 25vw;
  }
}

</style>
