import request, {DELETE, GET, POST, PATCH} from '@/capabilities/request';
import { success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useWishStore } from '@/stores/wish';
import { api } from '@/config';
const domain = api();

class WishService {

  constructor(accountStore, wishStore) {
    this._accountStore = accountStore;
    this._wishStore = wishStore;
  }

  async list(pid) {
    const account_pid = this._accountStore?.account?.pid
    const _pid = pid || account_pid;
    const { response } = await request(GET, `${domain}/wish/list/${_pid}`)
    if(success(response)) {
      const items = await response.json();
      if(_pid === account_pid) {
        this._wishStore.list(items);
      }
      return items;
    }
  }

  async create(wish) {
    const { token } = this._accountStore;
    const { response } = await request(POST, `${domain}/wish`, wish, token);
    const newWish = await response.json();
    this._wishStore.create(newWish);
    return newWish;
  }

  async remove(wish) {
    const { token } = this._accountStore;
    const { response } = await request(DELETE, `${domain}/wish/${wish.id}`, null, token);
    if(success(response)) {
      this._wishStore.remove(wish);
      return wish;
    }
  }

  async update(wish) {
    const { token } = this._accountStore;
    const { id, ...data } = wish;
    const { response } = await request(PATCH, `${domain}/wish/${id}`, data, token);
    const newWish = await response.json();
    this._wishStore.update(newWish);
    return newWish;
  }

  /**
   * Adds a wish for a product outside of Gifthorse.
   * @param values - Base64 encoded JSON values
   * @returns {Promise<*>}
   */

  async add(values) {
    const { token } = this._accountStore;
    const wish = JSON.parse(atob(values));
    const { response } = await request(POST, `${domain}/wish/add`, wish, token);
    return await response.json();
  }

}

let instance;
export const useWishService = () =>
  instance || (instance = new WishService(useAccountStore(), useWishStore()), instance)
