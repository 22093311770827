<template>
  <div>
    <label class="label" :for="`${name}_${uid}`">{{name}}</label>
    <select class="select" :id="`${name}_${uid}`" :name="`variant_${name}`" @change="handleChange">
      <option value="">Select {{name}}</option>
      <option v-for="([key, value]) in Object.entries(values)" :key="key" :value="key">{{value}}</option>
    </select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { identity } from 'ku4es-kernel';
const props = defineProps(['id', 'name', 'values']);
const emit = defineEmits(['change']);
const uid = identity.uid();
const handleChange = e => emit('change', e);
</script>

<style scoped>
div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
</style>
