import { Assert } from 'ku4es-kernel';
import { Dto } from 'ku4es-ui-kernel';
import request, { GET, PATCH, POST, PUT } from '@/capabilities/request';
import { conflict, success } from '@/capabilities/response';
import { useAccountStore } from '@/stores/account';
import { useFollowStore } from '@/stores/follow';
import { usePersonStore } from '@/stores/person';
import { useProductStore } from '@/stores/product';
import { useSettingsStore } from '@/stores/settings';
import { useSuggestionStore } from '@/stores/suggestion';
import { useVendorStore } from '@/stores/vendor';
import { useWardStore } from '@/stores/ward';
import { useWishStore } from '@/stores/wish';
import { api } from '@/config';
const domain = api();

class AccountService {

  constructor(accountStore, followStore, personStore, productStore, settingsStore, suggestionStore, vendorStore, wardStore, wishStore) {
    this._accountStore = accountStore;
    this._followStore = followStore;
    this._personStore = personStore;
    this._productStore = productStore;
    this._settingsStore = settingsStore;
    this._suggestionStore = suggestionStore;
    this._vendorStore = vendorStore;
    this._wardStore = wardStore;
    this._wishStore = wishStore;
  }

  async login(username, password) {
    try {
      const { response } = await request(POST, `${domain}/account/login`, {username, password});
      if(success(response)) {
        const authorization = (response.headers.get('Authorization') || '').replace('Bearer ', '');
        const remappedAuthorization = (response.headers.get('x-amzn-Remapped-Authorization') || '').replace('Bearer ', '');
        const token = authorization || remappedAuthorization;
        const account = await response.json();
        this._accountStore.login(token, account);
        await this.save();
        return account;
      }
      else {
        return { error: 'Invalid credentials.' }
      }
    }
    catch(e) {
      console.log('Account:login:error:', e);
    }
  }

  async create({ first, middle, last, dob, email, password }) {
    const { response } = await request(POST, `${domain}/account`, {
      first, middle, last, dob: (new Date(dob)).toJSON(), email, username: email, password
    });
    if (success(response)) {
      this._accountStore.create();
      return await response.json();
    }
    if (conflict(response)) { return { error: 'Invalid create request.' }; }
    return { error: 'Create request error.' };
  }

  async retrieveRecoveryToken(email) {
    const { response } = await request(POST, `${domain}/account/password/token`, { email, origin: location.origin });
    if (success(response)) { return await response.json(); }
    if (conflict(response)) { return { error: 'Invalid recovery token request.' }; }
    return { error: 'Recovery token error.' };
  }

  async resetPassword(password, confirmation, token) {
    const { response } = await request(PUT, `${domain}/account/password/reset`, { password, confirmation, token });
    if (success(response)) { return await response.json(); }
    if (conflict(response)) { return { error: 'Invalid reset password request.' }; }
    return { error: 'Reset password error.' };
  }

  /**
   * Generate a code for a ward to login without needing an email.
   * E.g. a young son does not have an email address so the mother
   * creates the account for the child and then generates a proxy
   * code for the child to login on the child's device
   * @param pid
   * @returns {Promise<*|null>}
   */
  async generateProxyLoginCode(pid) {
    const { token } = this._accountStore;
    const { response } = await request(GET, `${domain}/account/proxy/${pid}`, null, token);
    return success(response) ? await response.json() : null;
  }

  /**
   * Login for a ward using the code generated from
   * a call to `generateProxyLoginCode`.
   * @param code
   * @returns {Promise<*|{error: string}>}
   */
  async loginWithProxyLoginCode(code) {
    try {
      const { response } = await request(GET, `${domain}/account/proxy/login`, { code });
      if(success(response)) {
        const authorization = (response.headers.get('Authorization') || '').replace('Bearer ', '');
        const remappedAuthorization = (response.headers.get('x-amzn-Remapped-Authorization') || '').replace('Bearer ', '');
        const token = authorization || remappedAuthorization;
        const account = await response.json();
        this._accountStore.login(token, account);
        await this.save();
        return account;
      }
      else {
        return { error: 'Invalid verification code.' }
      }
    }
    catch(e) { console.log('Account:proxyLogin:error:', e); }
  }

  async loginAsWard(pid) {
    const { code } = await this.generateProxyLoginCode(pid);
    try {
      const { response } = await request(GET, `${domain}/account/proxy/login`, { code });
      if(success(response)) {
        const authorization = (response.headers.get('Authorization') || '').replace('Bearer ', '');
        const remappedAuthorization = (response.headers.get('x-amzn-Remapped-Authorization') || '').replace('Bearer ', '');
        const token = authorization || remappedAuthorization;
        const account = await response.json();
        this._accountStore.loginAsWard(token, account);
        await this.save();
        return account;
      }
      else {
        return { error: 'Invalid credentials.' }
      }
    }
    catch(e) { console.log('Account:loginAsWard:error:', e); }
  }

  async switchUser(pid) {
    if(Assert.exists(this._accountStore.accounts[pid])) {
      this._accountStore.switchUser(pid);
    }
    else {
      await this.loginAsWard(pid);
    }
    this._followStore.reset();
    this._settingsStore.reset();
    this._suggestionStore.reset();
    this._wardStore.reset();
    this._wishStore.reset();
    await this.save();
    return this._accountStore.account;
  }

  /**
   * Load any persisted account data. Used with navigation guard
   * for persistent login.
   * @returns {Promise<AccountService>}
   */
  async load() {
    const dto = await Dto.load('account');
    if (Assert.exists(dto)) {
      this._accountStore.load(dto.value);
    }
    return this;
  }

  /**
   * Save the current store state to local persistence for future
   * load for persistent login.
   * @returns {Promise<AccountService>}
   */
  async save() {
    const dto = await Dto.load('account');
    (Assert.exists(dto))
      ? (await dto.update(this._accountStore.read()).save())
      : await (new Dto(this._accountStore.read(), 'account')).save();
    return this;
  }

  /**
   * Read values off of the store.
   * @param value
   * @returns {*}
   */
  read(value) {
    return this._accountStore.read(value);
  }

  async update({ first, middle, last, dob }) {
    const { token, account } = this._accountStore;
    const { response } = await request(PATCH,
      `${domain}/person/${account.pid}`,
      { first, middle, last, dob },
      token
    );
    if (success(response)) {
      const updates = await response.json();
      this._accountStore.update(updates);
      return updates;
    }
    else {
      return response;
    }
  }

  async logout() {
    await Dto.load('account').then(dto => dto.delete());
    this._accountStore.reset();
    this._followStore.reset();
    this._personStore.reset();
    this._productStore.reset();
    this._settingsStore.reset();
    this._suggestionStore.reset();
    this._vendorStore.reset();
    this._wardStore.reset();
    this._wishStore.reset();
  }

  async updateImage({ image }) {
    const { token, account } = this._accountStore;
    return await request(PUT, `${domain}/person/${account.pid}/image`, image, token);
  }

}

let instance;
export const useAccountService = () =>
  instance || (
    instance = new AccountService(
      useAccountStore(),
      useFollowStore(),
      usePersonStore(),
      useProductStore(),
      useSettingsStore(),
      useSuggestionStore(),
      useVendorStore(),
      useWardStore(),
      useWishStore()
    ), instance)
